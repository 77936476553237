import BaseObject from "./BaseObject";
import DeckBase from "./DeckBase";
import MainScene from "./MainScene";
import RessourceBar from "./RessourceBar";

export default class CardBase extends BaseObject {
    
    public card: Phaser.GameObjects.Image;
    public background: Phaser.GameObjects.Rectangle;
    public healthBar: RessourceBar;
    public cardScale: number = 0.15;
    public key: string;
    public isDead: boolean = false;
    public baseHealth: number = 100;
    public isFlipped: boolean = false;
    public isHitting: boolean = false;
    public birtdate: Date = new Date();
    private _health: number = 100;

    constructor(scene: MainScene, x: number, y: number, elements: any[]) {

        const card = new Phaser.GameObjects.Image(scene, 0, 0, 'card');
        const background = new Phaser.GameObjects.Rectangle(scene, 0, 0, scene.sys.game.canvas.width * 2, scene.sys.game.canvas.height * 2, 0x000000, 0.7);
        const healthBar = new RessourceBar({ scene, x: 0, y: -75, value: 100, maxValue: 100, width: 200 });

        super(scene, x, y, [background, card, healthBar, ...elements]);
        this.card = card;
        this.healthBar = healthBar;
        this.background = background;

        this.card.setScale(this.cardScale);
        this.background.setVisible(false);
        this.background.setInteractive();
        this.setSize(this.card.width * this.cardScale, this.card.height * this.cardScale);

        this.background.on('pointerup', (pointer) => {
            this.toggleFlip();
        });

        this.scene.add.existing(this);
    }
    
    get speed(): number {
        return 1.0;
    }
    
    get health(): number {
        return this._health;
    }
    set health(value: number) {
        this._health = value;
    }
    
    get maxHealth(): number {
        return this.baseHealth;
    }

    cardAddedToDeck(deck: DeckBase): void {
    }

    cardRemovedFromDeck(deck: DeckBase): void {
    }

    toggleFlip() {
    }
}
