export default class RessourceBar extends Phaser.GameObjects.Container {
    constructor(data) {
        let { scene, x, y, value, maxValue, width } = data;

        const bar = new Phaser.GameObjects.Graphics(scene);

        super(scene, x, y, [bar]);

        this.width = width;
        this.step = (this.width - 4) / maxValue;
        
        this.bar = bar;
        this.x = x - (this.width / 4);
        this.y = y;
        this.value = value;
        this.maxValue = maxValue;

        this.draw();

        scene.add.existing(this);
        this.onComplete = new Phaser.Events.EventEmitter();
    }

    addToValue(amount) {
        this.value += amount;
        if (this.value > this.maxValue) {
            this.value = this.maxValue;
        }
        if (this.value < 0) {
            this.value = 0;
        }
        this.draw();
        if (this.value >= this.maxValue) {
            this.value = 0;
            this.onComplete.emit('complete');
            this.scene.time.delayedCall(100, () => {
                this.draw();
            });
        }
    }

    set(value) {
        this.value = value;
        this.draw();
    }

    setMax(value) {
        this.maxValue = value;
        this.step = (this.width - 4) / value;
        this.draw();
    }

    draw() {
        this.bar.clear();

        this.bar.fillStyle(0x000000);
        this.bar.fillRect(this.x, this.y, this.width, 20);

        this.bar.fillStyle(0xffffff);
        this.bar.fillRect(this.x + 4, this.y + 4, this.width - 6, 16);
        
        this.bar.fillStyle(0xff0000a);
        this.bar.fillRect(this.x + 4, this.y + 4, Math.floor(this.step * this.value), 16);
    }
}