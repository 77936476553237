import items from "./Items.js";

export default class Inventory {
    constructor(maxColumns) {
        this.maxColumns = maxColumns || 8;
        this.maxRows = 7;
        this.selected = 0;
        this.observers = [];
        this.items = {};
    }

    subscribe(fn) {
        this.observers.push(fn);
    }
    unsubscribe(fn) {
        this.observers = this.observers.filter((subscriber) => subscriber !== fn);
    }

    broadcast(action, data) {
        this.observers.forEach((subscriber) => subscriber(action, data));
    }

    addItem(item) {
        let existingKey = Object.keys(this.items).find(i => this.items[i].key === item.key);
        if (existingKey) {
            this.items[existingKey].quantity += item.quantity;
        } else {
            for (let index = 0; index < this.maxColumns * this.maxRows; index++) {
                let existingItem = this.items[index];
                if (!existingItem) {
                    this.items[index] = item;
                    break;
                }
            }
        }
        this.broadcast();
    }

    removeItem(itemName, quantity) {
        let existingKey = Object.keys(this.items).find((i) => this.items[i].key == itemName);
        if (existingKey) {
            this.items[existingKey].quantity -= quantity;
            if (this.items[existingKey].quantity <= 0) delete this.items[existingKey];
        }
        this.broadcast();
    }

    getItem(index) {
        return this.items[index];
    }

    moveItem(start, end, item) {
        if (start === end || this.items[end]) return;
        this.items[end] = this.items[start];
        delete this.items[start];
    }

    get selectedItem() {
        return this.items[this.selected];
    }

    getItemFrame(item) {
        return items[item.key].frame;
    }

    getItemInfo(itemName) {
        return items[itemName];
    }

    // getItemByName(key) {
    //   let existingKey = Object.keys(this.items).find((i) => this.items[i].key == key);
    //   return this.items[existingKey];
    //   //return this.items.find((i) => i.key === key);
    // }

    getItemQuantity(itemName) {
        return Object.values(this.items).filter(i => i.key === itemName).map(i => i.quantity).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }
}