export default class ButtonNavigate extends Phaser.GameObjects.Container {
    constructor(data) {
        let { scene, x, y, width, height, direction } = data;

        const navigate = new Phaser.GameObjects.Graphics(scene);
        const background = new Phaser.GameObjects.Rectangle(scene, 0, 0, width, height, 0xffffff, 0.1);

        super(scene, x, y, [background, navigate]);

        this.navigate = navigate;
        this.scene = scene;
        this.width = width;
        this.height = height;
        this.x = x;
        this.y = y;
        this.direction = direction;
        this.events = new Phaser.Events.EventEmitter();

        this.setSize(width, height);
        this.setInteractive();
        this.input.priorityID = 1;

        this.on('pointerup', (pointer) => {
            this.events.emit('click');
        });

        this.draw();

        scene.add.existing(this);
    }

    draw() {
        this.navigate.lineStyle(15, 0xffffff, 1);
        if (this.direction === 'left') {
            this.navigate.lineBetween(-20, -4, 30, 50);
            this.navigate.lineBetween(-20, 4, 30, -50);
        } else {
            this.navigate.lineBetween(30, 4, -20, -50);
            this.navigate.lineBetween(30, -4, -20, 50);
        }
    }
}