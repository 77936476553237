import CardBase from './CardBase';
import SpawnItem from './SpawnItem';
import enemies from './Enemies';
import rewards from './Rewards';

export default class CardEnemy extends CardBase {
    constructor(data) {

        const textLevelOptions = {
            font: "30px Arial",
            fill: "#111"
        };

        let { scene, x, y, isFlipped, depth, id } = data;
        const ressource = new Phaser.GameObjects.Image(scene, 0, 52, 'monster-1');
        const levelText = new Phaser.GameObjects.Text(scene, -50, -130, 'Level 1', textLevelOptions);

        super(scene, x, y, [ressource, levelText]);

        this.ressource = ressource;
        this.levelText = levelText;
        this.scene = scene;
        this.isFlipped = isFlipped;
        this.id = id;
        this._dropOptions = [];

        if (enemies['level' + this.level]) {
            this._dropOptions = enemies['level' + this.level].dropOptions.map(item => {
                return {
                    key: item.key,
                    chance: item.chance,
                }
            });
        } else {
            this._dropOptions = [
                { key: 'plus-' + this.scene.player.tier, chance: 100 },
            ];
        }

        this.ressource.setTexture('monster-' + this.level);
        this.ressource.setVisible(this.isFlipped);
        this.ressource.setScale(0.5);
        this.setDepth(depth);

        this.levelText.setText('Level ' + this.level);
        this.levelText.setOrigin(0.5, 0);
        this.levelText.setVisible(this.isFlipped);

        this.healthBar.setMax(this.maxHealth);
        this.healthBar.set(this.maxHealth);
        this.healthBar.setVisible(this.isFlipped);

        this.health = this.maxHealth;

        this.rewards = [];

        for (let index = 0; index < this.dropOptions.length; index++) {
            let x = -70;
            let y = (index * 105) + 20;
            const dropOption = this.dropOptions[index];
            const slot = this.scene.add.rectangle(x, y, 100, 100, 0xffffff, 0.5);
            slot.setDepth(3);
            slot.setVisible(this.isFlipped);
            this.add(slot);

            const border = this.scene.add.graphics();
            border.lineStyle(4, 0xffffff, 1);
            border.strokeRoundedRect(x - 50, y - 50, 96, 96, 5);
            border.setDepth(3);
            border.setVisible(this.isFlipped);
            this.add(border);
            
            slot.item = this.scene.add.image(slot.x, slot.y, dropOption.key.replace('${tier}', this.scene.player.tier));
            slot.item.setScale(0.4);
            slot.item.setDepth(3);
            slot.item.setVisible(this.isFlipped);
            this.add(slot.item);

            slot.text = this.scene.add.text(slot.x, slot.y + 10, dropOption.chance + '%', {
                font: "30px Arial",
                fill: "#ffffff",
                stroke: '#111',
                strokeThickness: 6,
            });
            slot.text.setOrigin(0.5, 0);
            slot.text.setDepth(3);
            slot.text.setVisible(this.isFlipped);
            this.add(slot.text);

            this.rewards.push(slot);
        }

        this.scene.add.existing(this);
    }

    get level() {
        let result = 1;
        rewards.forEach(reward => {
            if (this.scene.player.rewards[reward.key] === true && reward.bossLevel && result < reward.bossLevel + 1) {
                result = reward.bossLevel + 1;
            }
        });
        return result;
    }

    get speed() {
        if (enemies['level' + this.level]) {
            return enemies['level' + this.level].speed;
        }
        return 1.0;
    }

    get strength() {
        if (enemies['level' + this.level]) {
            return enemies['level' + this.level].strength;
        }
        return 1.0;
    }

    get maxHealth() {
        if (enemies['level' + this.level]) {
            return enemies['level' + this.level].maxHealth;
        }
        return 100;
    }

    get dropOptions() {
        return this._dropOptions;
    }

    die(source, deck) {
        if (!this.isDead) {
            this.isDead = true;
            source.health = source.maxHealth;
            source.healthBar.setMax(source.health);
            source.healthBar.set(source.health);
            source.birthdate = new Date();

            console.log('enemy died in', (new Date().getTime() - this.birtdate.getTime())/ 1000)
            this.birtdate = new Date();

            this.scene.tweens.add({
                targets: [this],
                alpha: 0.1,
                yoyo: false,
                repeat: 0,
                duration: 100,
                ease: 'Sine.easeInOut',
                onComplete: () => {
                    this.setVisible(false);
                },
            });

            // drops
            if (this.dropOptions && this.dropOptions.length > 0) {
                new SpawnItem({
                    key: this.getDropOption(this.key, []),
                    scene: this.scene,
                    mainscene: this.scene,
                    x: this.x - (this.width / 1.5),
                    y: this.y - (this.height / 2),
                    dirStart: 0,
                    dirStop: 360,
                });
            }

            // get next target
            deck.removeCard(this);

            this.scene.time.delayedCall(300, () => {
                
                // move cards down
                deck.moveCard(0, 1);
                const card = deck.moveCard(1, 2);

                // console.log('deck.cards[2]', deck.cards[2])
                /* deck.cards[2] = new CardEnemy({
                    scene: this,
                    x: -150,
                    y: deck.getCoordinatesY(2),
                    isFlipped: false,
                    depth: 0,
                    id: 0,
                }); */

                // add a new card from below
                deck.addCard(new CardEnemy({
                    scene: this.scene,
                    x: -150,
                    y: deck.getCoordinatesY(0),
                    isFlipped: false,
                    depth: 0,
                    id: 4,
                }));

                //
                this.scene.time.delayedCall(200, () => {
                    card.toggleFlip();
                });
            });
        }
    }

    hit(destination, deck) {
        this.isHitting = true;
        const multi = 10;
        const x = this.x;
        const y = this.y;
        this.scene.tweens.add({
            targets: [this],
            props: {
                x: { value: () => { return destination.x; }, ease: 'Power1' },
                y: { value: () => { return destination.y; }, ease: 'Power3' }
            },
            duration: 100,
            yoyo: false,
            repeat: 0,
            onComplete: () => {

                const damage = Phaser.Math.Between((this.strength - (this.strength / 10)) * multi, (this.strength + (this.strength / 10)) * multi);
                destination.health -= damage;
                destination.healthBar.addToValue(-damage);

                if (destination.health <= 0) {
                    destination.die(this, deck);
                }

                this.scene.floatingNumbers.createFloatingText({
                    textOptions: {
                        fontFamily: 'arial',
                        fontSize: 50,
                        color: "#ff0000",
                        strokeThickness: 4,
                        fontWeight: "800",
                        stroke: "#000000",
                    },
                    text: damage,
                    timeToLive: 800,
                    align: "top-center",
                    parentObject: destination,
                    animation: "up",
                    animationEase: "Linear",
                });

                this.scene.tweens.add({
                    targets: [this],
                    props: {
                        x: { value: () => { return x; }, ease: 'Power3' },
                        y: { value: () => { return y; }, ease: 'Power1' }
                    },
                    duration: 200,
                    yoyo: false,
                    repeat: 0,
                    onComplete: () => {
                        this.isHitting = false;
                    },
                });
            },
        });
    }

    toggleFlip() {
        this.birtdate = new Date();
        this.scene.tweens.add({
            targets: this.card,
            scaleX: this.cardScale * 0.01,
            ease: 'Linear',
            duration: 150,
            onComplete: () => {
                this.ressource.setVisible(!this.isFlipped);
                this.healthBar.setVisible(!this.isFlipped);
                this.levelText.setVisible(!this.isFlipped);
                this.scene.tweens.add({
                    targets: this.card,
                    scaleX: this.cardScale,
                    ease: 'Linear',
                    duration: 150,
                    onComplete: () => {
                        this.isFlipped = !this.isFlipped;

                        if (this.isFlipped) {
                            this.rewards.forEach(reward => {
                                reward.setVisible(true);
                                reward.item.setVisible(true);
                                reward.text.setVisible(true);
                            });
                        }
                    },
                })
            },
        });
    }
}
