import CardBase from "./CardBase";
import MainScene from "./MainScene";

export default class DeckBase {

    public limit: number = 0;
    public cards: Array<CardBase> = [];
    public name: string;
    public scene: MainScene;
    public isEnemyDeck: boolean = false;

    constructor(scene: MainScene, name: string) {
        this.name = name;
        this.scene = scene;
    }

    orderCards() {
        this.cards.forEach((item: CardBase, index: number) => {
            return item.setDepth(index + 1);
        });
    }

    get length(): number {
        return this.cards.length;
    }

    getPlayer(): CardBase {
        return this.cards.find(card => card.constructor.name === 'Card');
    }

    getCoordinatesY(index: number): number {
        return 0;
    }
}