import ButtonPlus from "./ButtonPlus";
import CardBase from '../js/CardBase';
import rewards from "./Rewards.js";
import ButtonNavigate from "./ButtonNavigate";

const LUCK_PERCENT = 10;

export default class Card extends CardBase {

    constructor(data) {
        let { scene, x, y, key, name, plus, shield, luck, strengthIncrease, speedIncrease, healthIncrease, criticalIncrease, dropZone } = data;
        
        const textOptions = {
            font: "40px Arial",
            fill: "#111"
        };

        const textTitleOptions = {
            font: "bold 40px Arial",
            fill: "#111"
        };

        const textPlusOptions = {
            font: "40px Arial",
            fill: "#228B22"
        };

        const textPlusItemOptions = {
            font: "35px Arial",
            fill: "#ffffff"
        };

        const textPlusFrontOptions = {
            font: "40px Arial",
            fill: "#111"
        };

        const textLuckOptions = {
            font: "40px Arial",
            fill: "#228B22"
        };

        const cardBorderWidth = 10;

        const ressource = new Phaser.GameObjects.Image(scene, 0, 0, key);
        const footer = new Phaser.GameObjects.Graphics(scene);

        const plusItem = new Phaser.GameObjects.Image(scene, 0, 0, 'plus-1');
        const plusItemQuantityText = new Phaser.GameObjects.Text(scene, 0, 0, '0', textPlusItemOptions);

        const shieldItem = new Phaser.GameObjects.Image(scene, 0, 0, 'shield-1');
        const shieldItemQuantityText = new Phaser.GameObjects.Text(scene, 0, 0, '0', textPlusItemOptions);

        const luckItem = new Phaser.GameObjects.Image(scene, 0, 0, 'luck');
        const luckItemQuantityText = new Phaser.GameObjects.Text(scene, 0, 0, '0', textPlusItemOptions);
        
        const plusText = new Phaser.GameObjects.Text(scene, 0, 0, '+' + (plus || 0), textPlusFrontOptions);

        const plusLabelText = new Phaser.GameObjects.Text(scene, 0, 0, 'Level', textTitleOptions);
        const plusValueText = new Phaser.GameObjects.Text(scene, 0, 0, '+' + (plus || 0), textTitleOptions);
        const plusIncreaseText = new Phaser.GameObjects.Text(scene, 0, 0, '0%', textPlusOptions);

        const riskLabelText = new Phaser.GameObjects.Text(scene, 0, 0, '- Risk', textOptions);
        const riskValueText = new Phaser.GameObjects.Text(scene, 0, 0, '0%', textOptions);
        const riskIncreaseText = new Phaser.GameObjects.Text(scene, 0, 0, '0', textOptions);

        const luckLabelText = new Phaser.GameObjects.Text(scene, 0, 0, '- Luck', textOptions);
        const luckValueText = new Phaser.GameObjects.Text(scene, 0, 0, '0%', textOptions);
        const luckIncreaseText = new Phaser.GameObjects.Text(scene, 0, 0, '0', textLuckOptions);

        const strengthLabelText = new Phaser.GameObjects.Text(scene, 0, 0, 'Strength', textOptions);
        const strengthValueText = new Phaser.GameObjects.Text(scene, 0, 0, '1.0', textOptions);
        const strengthIncreaseText = new Phaser.GameObjects.Text(scene, 0, 0, '0%', textPlusOptions);

        const speedLabelText = new Phaser.GameObjects.Text(scene, 0, 0, 'Speed', textOptions);
        const speedValueText = new Phaser.GameObjects.Text(scene, 0, 0, '1.0', textOptions);
        const speedIncreaseText = new Phaser.GameObjects.Text(scene, 0, 0, '0%', textPlusOptions);

        const healthLabelText = new Phaser.GameObjects.Text(scene, 0, 0, 'Health', textOptions);
        const healthValueText = new Phaser.GameObjects.Text(scene, 0, 0, '100', textOptions);
        const healthIncreaseText = new Phaser.GameObjects.Text(scene, 0, 0, '0%', textPlusOptions);

        const criticalLabelText = new Phaser.GameObjects.Text(scene, 0, 0, 'Critical', textOptions);
        const criticalValueText = new Phaser.GameObjects.Text(scene, 0, 0, '1.0', textOptions);
        const criticalIncreaseText = new Phaser.GameObjects.Text(scene, 0, 0, '0%', textPlusOptions);

        const plusButtonEnhance = new ButtonPlus(scene, 0, 0, 'dice');
        const shieldButtonEnhance = new ButtonPlus(scene, 0, 0, 'shield-blue');
        const luckButtonEnhance = new ButtonPlus(scene, 0, 0, 'shield-green');
        const strengthButtonEnhance = new ButtonPlus(scene, 0, 0, 'dice');
        const speedButtonEnhance = new ButtonPlus(scene, 0, 0, 'dice');
        const healthButtonEnhance = new ButtonPlus(scene, 0, 0, 'dice');
        const criticalButtonEnhance = new ButtonPlus(scene, 0, 0, 'dice');

        const goLeft = new ButtonNavigate({ scene, x: 0, y: 0, width: 100, height: 200, direction: 'left' });
        const goRight = new ButtonNavigate({ scene, x: 0, y: 0, width: 100, height: 200, direction: 'right' });

        super(scene, x, y, [
            footer,
            plusItem,
            plusItemQuantityText,
            shieldItem,
            shieldItemQuantityText,
            luckItem,
            luckItemQuantityText,
            ressource,
            plusText,
            plusLabelText,
            plusIncreaseText,
            plusValueText,
            riskLabelText,
            riskValueText,
            riskIncreaseText,
            luckLabelText,
            luckValueText,
            luckIncreaseText,
            strengthLabelText,
            strengthIncreaseText,
            strengthValueText,
            speedLabelText,
            speedIncreaseText,
            speedValueText,
            healthLabelText,
            healthIncreaseText,
            healthValueText,
            criticalLabelText,
            criticalIncreaseText,
            criticalValueText,
            plusButtonEnhance,
            shieldButtonEnhance,
            luckButtonEnhance,
            strengthButtonEnhance,
            speedButtonEnhance,
            healthButtonEnhance,
            criticalButtonEnhance,
            goLeft,
            goRight,
        ]);

        this.inventory = scene.player.inventory;
        this.deck = null;
        this.dropZone = dropZone;
        this.key = key;
        this.name = name;
        this.plusText = plusText;
        this.ressource = ressource;
        this.footer = footer;
        this.plusLabelText = plusLabelText;
        this.plusIncreaseText = plusIncreaseText;
        this.plusValueText = plusValueText;
        this.riskLabelText = riskLabelText;
        this.riskValueText = riskValueText;
        this.riskIncreaseText = riskIncreaseText;
        this.luckLabelText = luckLabelText;
        this.luckValueText = luckValueText;
        this.luckIncreaseText = luckIncreaseText;
        this.strengthLabelText = strengthLabelText;
        this.strengthIncreaseText = strengthIncreaseText;
        this.strengthValueText = strengthValueText;
        this.speedLabelText = speedLabelText;
        this.speedIncreaseText = speedIncreaseText;
        this.speedValueText = speedValueText;
        this.healthLabelText = healthLabelText;
        this.healthIncreaseText = healthIncreaseText;
        this.healthValueText = healthValueText;
        this.criticalLabelText = criticalLabelText;
        this.criticalIncreaseText = criticalIncreaseText;
        this.criticalValueText = criticalValueText;
        this.plusButtonEnhance = plusButtonEnhance;
        this.shieldButtonEnhance = shieldButtonEnhance;
        this.luckButtonEnhance = luckButtonEnhance;
        this.strengthButtonEnhance = strengthButtonEnhance;
        this.speedButtonEnhance = speedButtonEnhance;
        this.healthButtonEnhance = healthButtonEnhance;
        this.criticalButtonEnhance = criticalButtonEnhance;
        this.plusItem = plusItem;
        this.plusItemQuantityText = plusItemQuantityText;
        this.shieldItem = shieldItem;
        this.shieldItemQuantityText = shieldItemQuantityText;
        this.luckItem = luckItem;
        this.luckItemQuantityText = luckItemQuantityText;
        this.goLeft = goLeft;
        this.goRight = goRight;

        this.plusIncreaseText.setText(this.plusPercent(plus) + '%');
        this.strengthIncreaseText.setText(this.attributePercent(strengthIncrease) + '%');
        this.speedIncreaseText.setText(this.attributePercent(speedIncrease) + '%');
        this.healthIncreaseText.setText(this.attributePercent(healthIncrease) + '%');
        this.criticalIncreaseText.setText(this.attributePercent(criticalIncrease) + '%');

        this.plusItem.setTexture('plus-' + this.tier);
        this.shieldItem.setTexture('shield-' + this.tier);

        this.setName(name);
        this.setDepth(6);
        this.setInteractive();
        this.plusItem.setScale(0.3);
        this.shieldItem.setScale(0.3);
        this.luckItem.setScale(0.3);
        this.plusButtonEnhance.setDepth(12);
        this.shieldButtonEnhance.setDepth(12);
        this.luckButtonEnhance.setDepth(12);
        this.plusButtonEnhance.dice.tint = 0xffDDDD;

        // TODO: unsub on close
        this.inventory.subscribe(() => {
            this.updatePlusItem();
            this.updateShieldItem();
            this.updateLuckItem();
        });

        this.showFront();

        this.plusValueText.setOrigin(1, 0);
        this.riskValueText.setOrigin(1, 0);
        this.riskIncreaseText.setOrigin(1, 0);
        this.luckValueText.setOrigin(1, 0);
        this.luckIncreaseText.setOrigin(1, 0);
        this.strengthValueText.setOrigin(1, 0);
        this.speedValueText.setOrigin(1, 0);
        this.healthValueText.setOrigin(1, 0);
        this.criticalValueText.setOrigin(1, 0);
        this.plusIncreaseText.setOrigin(1, 0);
        this.strengthIncreaseText.setOrigin(1, 0);
        this.speedIncreaseText.setOrigin(1, 0);
        this.healthIncreaseText.setOrigin(1, 0);
        this.criticalIncreaseText.setOrigin(1, 0);
        this.plusItemQuantityText.setOrigin(0.5, 0.5);
        this.shieldItemQuantityText.setOrigin(0.5, 0.5);
        this.luckItemQuantityText.setOrigin(0.5, 0.5);

        this.plusLabelText.setPosition(-this.width + cardBorderWidth, -350);
        this.plusValueText.setPosition(cardBorderWidth * 4, -350);
        this.plusIncreaseText.setPosition((this.width / 2) + (cardBorderWidth * 4), -350);
        this.plusButtonEnhance.setPosition(250, -335);

        this.riskLabelText.setPosition(-this.width + cardBorderWidth, -270);
        this.riskValueText.setPosition(cardBorderWidth * 4, -270);
        this.riskIncreaseText.setPosition((this.width / 2) + (cardBorderWidth * 4), -270);
        this.shieldButtonEnhance.setPosition(245, -250);

        this.luckLabelText.setPosition(-this.width + cardBorderWidth, -190);
        this.luckValueText.setPosition(cardBorderWidth * 4, -190);
        this.luckIncreaseText.setPosition((this.width / 2) + (cardBorderWidth * 4), -190);
        this.luckButtonEnhance.setPosition(245, -165);

        this.strengthLabelText.setPosition(-this.width + cardBorderWidth, -50);
        this.strengthValueText.setPosition(cardBorderWidth * 4, -50);
        this.strengthIncreaseText.setPosition((this.width / 2) + (cardBorderWidth * 4), -50);
        this.strengthButtonEnhance.setPosition(this.width - (cardBorderWidth * 2), -25);

        this.speedLabelText.setPosition(-this.width + cardBorderWidth, 50);
        this.speedValueText.setPosition(cardBorderWidth * 4, 50);
        this.speedIncreaseText.setPosition((this.width / 2) + (cardBorderWidth * 4), 50);
        this.speedButtonEnhance.setPosition(this.width - (cardBorderWidth * 2), 75);

        this.healthLabelText.setPosition(-this.width + cardBorderWidth, 150);
        this.healthValueText.setPosition(cardBorderWidth * 4, 150);
        this.healthIncreaseText.setPosition((this.width / 2) + (cardBorderWidth * 4), 150);
        this.healthButtonEnhance.setPosition(this.width - (cardBorderWidth * 2), 175);

        this.criticalLabelText.setPosition(-this.width + cardBorderWidth, 250);
        this.criticalValueText.setPosition(cardBorderWidth * 4, 250);
        this.criticalIncreaseText.setPosition((this.width / 2) + (cardBorderWidth * 4), 250);
        this.criticalButtonEnhance.setPosition(this.width - (cardBorderWidth * 2), 275);

        this.plusItem.setPosition(-90, 360);
        this.plusItemQuantityText.setPosition(-90, 410);
        this.shieldItem.setPosition(0, 360);
        this.shieldItemQuantityText.setPosition(0, 410);
        this.luckItem.setPosition(90, 360);
        this.luckItemQuantityText.setPosition(90, 410);

        this.goLeft.setVisible(false);
        this.goLeft.setPosition(-(this.width) - 65, 0);
        this.goLeft.events.on('click', () => {
            if (this.deck) {
                this.openPreviousCard(this.deck.cards, false);
            } else {
                this.openPreviousCard(this.scene.player.cards, true);
            }
        });

        this.goRight.setVisible(false);
        this.goRight.setPosition(this.width + 70, 0);
        this.goRight.events.on('click', () => {
            if (this.deck) {
                this.openNextCard(this.deck.cards, false);
            } else {
                this.openNextCard(this.scene.player.cards, true);
            }
        });

        this.isDragging = false;
        this.lastX = 0;
        this.lastY = 0;
        this.textPlusFrontOptions = textPlusFrontOptions;
        this.enhanceDelay = 300;

        this.footer.clear();
        this.footer.fillStyle(0x000000, 0.6);
        this.footer.fillRect(-(this.width)-40, this.height - 65, this.width * 2 + 80, 130);

        this._plus = plus || 0;
        this._shield = shield || 0;
        this._luck = luck || 0;
        this._strength = parseFloat(this.tier); // 1.0
        this._strengthIncrease = strengthIncrease || 0;
        this._speed = 1.0;
        this._speedIncrease = speedIncrease || 0;
        this._health = 100;
        this._healthIncrease = healthIncrease || 0;
        this._critical = 1.0;
        this._criticalIncrease = criticalIncrease || 0;

        if (this.ressource.texture.key.indexOf('sword-') > -1 && this.plus > 0) {
            this.ressource.setTexture('sword-' + (this.plus + 1));
        }

        this.strengthValueText.setText(this.strength.toFixed(1));
        this.speedValueText.setText(this.speed.toFixed(1));
        this.healthValueText.setText(this.maxHealth.toFixed(0));
        this.criticalValueText.setText(this.critical.toFixed(1));

        this.plusText.setOrigin(0.5, 0);
        this.plusText.setPosition(this.width / 2 - 65, this.height / 2 - 95);
        this.plusText.setVisible(true);

        this.riskValueText.setText(this.shield);
        this.luckValueText.setText(this.luck);

        this.scene.input.setDraggable(this);

        this.on('pointerup', (pointer) => {
            this.toggleFlip();
        });

        this.registerEvents();
        this.updateChance();

        this.scene.add.existing(this);
    }

    get tier() {
        return parseInt(this.key.split('-')[1]);
    }

    get plus() {
        return this._plus;
    }
    set plus(value) {
        this._plus = value;
        this.plusValueText.setText('+' + this._plus);
        this.plusIncreaseText.setText(this.plusPercent(this._plus) + '%');
        this.plusText.setText('+' + this._plus);
        this.strengthValueText.setText(this.strength.toFixed(1));
        this.speedValueText.setText(this.speed.toFixed(1));
        this.healthValueText.setText(this.maxHealth.toFixed(0));
        this.criticalValueText.setText(this.critical.toFixed(1));

        this.updateChance();

        this.scene.player.updateCard(this.name, this.x, this.y, this.plus, this.shield, this.luck, this._strengthIncrease, this._speedIncrease, this._healthIncrease, this._criticalIncrease);
    }

    get shield() {
        return this._shield;
    }
    set shield(value) {
        this._shield = value;
        this.riskValueText.setText(this._shield);

        this.updateChance();

        this.scene.player.updateCard(this.name, this.x, this.y, this.plus, this.shield, this.luck, this._strengthIncrease, this._speedIncrease, this._healthIncrease, this._criticalIncrease);
    }

    get luck() {
        return this._luck;
    }
    set luck(value) {
        this._luck = value;
        this.luckValueText.setText(this._luck);

        this.updateChance();

        this.scene.player.updateCard(this.name, this.x, this.y, this.plus, this.shield, this.luck, this._strengthIncrease, this._speedIncrease, this._healthIncrease, this._criticalIncrease);
    }

    get strengthIncrease() {
        return this._strengthIncrease;
    }
    set strengthIncrease(value) {
        this._strengthIncrease = value;
        this.strengthIncreaseText.setText(this.attributePercent(this._strengthIncrease) + '%');
        this.strengthValueText.setText(this.strength.toFixed(1));

        this.scene.player.updateCard(this.name, this.x, this.y, this.plus, this.shield, this.luck, this._strengthIncrease, this._speedIncrease, this._healthIncrease, this._criticalIncrease);
    }

    get strength() {
        let result = this._strength + (this._strength / 10 * this.plus) + (this.strengthIncrease / 25);
        if (this.scene.player.getRewardAttribute('strength')) {
            result += result * (this.scene.player.getRewardAttribute('strength') / 100);
        }
        return result;
    }

    get speedIncrease() {
        return this._speedIncrease;
    }
    set speedIncrease(value) {
        this._speedIncrease = value;
        this.speedIncreaseText.setText(this.attributePercent(this._speedIncrease) + '%');
        this.speedValueText.setText(this.speed.toFixed(1));

        this.scene.player.updateCard(this.name, this.x, this.y, this.plus, this.shield, this.luck, this._strengthIncrease, this._speedIncrease, this._healthIncrease, this._criticalIncrease);
    }

    get speed() {
        let result = this._speed + (this._speed / 10 * this.plus) + (this.speedIncrease / 25);
        if (this.scene.player.getRewardAttribute('speed')) {
            result += result * (this.scene.player.getRewardAttribute('speed') / 100);
        }
        return result;
    }

    get healthIncrease() {
        return this._healthIncrease;
    }
    set healthIncrease(value) {
        this._healthIncrease = value;
        this.healthIncreaseText.setText(this.attributePercent(this._healthIncrease) + '%');
        this.healthValueText.setText(this.maxHealth.toFixed(0));

        this.scene.player.updateCard(this.name, this.x, this.y, this.plus, this.shield, this.luck, this._strengthIncrease, this._speedIncrease, this._healthIncrease, this._criticalIncrease);
    }

    get maxHealth() {
        let result = this.baseHealth + (this.baseHealth / 10 * this.plus) + (this.healthIncrease * 2);
        if (this.scene.player.getRewardAttribute('health')) {
            result += result * (this.scene.player.getRewardAttribute('health') / 100);
        }
        return result;
    }

    get criticalIncrease() {
        return this._criticalIncrease;
    }
    set criticalIncrease(value) {
        this._criticalIncrease = value;
        this.criticalIncreaseText.setText(this.attributePercent(this._criticalIncrease) + '%');
        this.criticalValueText.setText(this.critical.toFixed(1));

        this.scene.player.updateCard(this.name, this.x, this.y, this.plus, this.shield, this.luck, this._strengthIncrease, this._speedIncrease, this._healthIncrease, this._criticalIncrease);
    }

    get critical() {
        let result = this._critical + (this._critical / 10 * this.plus) + (this.criticalIncrease / 25);
        if (this.scene.player.getRewardAttribute('critical')) {
            result += result * (this.scene.player.getRewardAttribute('critical') / 100);
        }
        return result;
    }

    openNextCard(cards, onlyWithoutDeck) {
        this.toggleFlip();
        let found = false;
        let toggled = false;
        const cardsToScan = onlyWithoutDeck ? cards.filter(card => card.deck === null) : cards;
        for (let i = 0; i < cardsToScan.length; i++) {
            const card = cardsToScan[i];
            if (found) {
                card.toggleFlip();
                found = false;
                toggled = true;
            }
            if (this.name === card.name) {
                found = true;
            }
        }
        if (!toggled) {
            cardsToScan[0].toggleFlip();
        }
    }

    openPreviousCard(cards, onlyWithoutDeck) {
        this.toggleFlip();
        let found = false;
        let toggled = false;
        const cardsToScan = onlyWithoutDeck ? cards.filter(card => card.deck === null) : cards;
        for (let i = cardsToScan.length - 1; i >= 0; i--) {
            const card = cardsToScan[i];
            if (found) {
                card.toggleFlip();
                found = false;
                toggled = true;
            }
            if (this.name === card.name) {
                found = true;
            }
        }
        if (!toggled) {
            cardsToScan[cardsToScan.length - 1].toggleFlip();
        }
    }

    die(source, deck) {
        if (!this.isDead) {
            this.isDead = true;
            source.health = source.maxHealth;
            source.healthBar.setMax(source.health);
            source.healthBar.set(source.health);
            source.birtdate = new Date();
            this.health = this.maxHealth;
            this.healthBar.setMax(this.health);
            this.healthBar.set(this.health);
            this.isDead = false;

            console.log('died in', (new Date().getTime() - this.birtdate.getTime())/ 1000)
            this.birtdate = new Date();
        }
    }

    hit(destination, deck) {

        this.isHitting = true;
        
        const multi = this.scene.player.cheatsActivated ? 1000 : 10;
        let damage = Phaser.Math.Between((this.strength - (this.strength / 10)) * multi, (this.strength + (this.strength / 10)) * multi);
        const isCritical = Phaser.Math.Between(0, 100) < this.critical;
        if (isCritical) {
            damage *= 2;
        }

        if (this.isFlipped) {
            this.isHitting = false;
            destination.health -= damage;
            destination.healthBar.addToValue(-damage);

            if (destination.health <= 0) {
                destination.die(this, deck);
            }
        } else {
            const x = this.x;
            const y = this.y;
            this.hitTween = this.scene.tweens.add({
                targets: [this],
                props: {
                    x: { value: () => { return destination.x; }, ease: 'Power1' },
                    y: { value: () => { return destination.y; }, ease: 'Power3' }
                },
                duration: 100,
                yoyo: false,
                repeat: 0,
                onComplete: () => {

                    destination.health -= damage;
                    destination.healthBar.addToValue(-damage);

                    if (destination.health <= 0) {
                        destination.die(this, deck);
                    }

                    this.scene.floatingNumbers.createFloatingText({
                        textOptions: {
                            fontFamily: 'arial',
                            fontSize: isCritical ? 100 : 50,
                            color: "#ff0000",
                            strokeThickness: 4,
                            fontWeight: "800",
                            stroke: "#000000",
                        },
                        text: damage,
                        timeToLive: 800,
                        align: "top-center",
                        parentObject: destination,
                        animation: isCritical ? "smoke" : "up",
                        animationDistance: 100,
                        animationEase: "Sine.easeOut",
                    });

                    this.scene.tweens.add({
                        targets: [this],
                        props: {
                            x: { value: () => { return x; }, ease: 'Power3' },
                            y: { value: () => { return y; }, ease: 'Power1' }
                        },
                        duration: 200,
                        yoyo: false,
                        repeat: 0,
                        onComplete: () => {
                            this.isHitting = false;
                        },
                    });
                },
            });
        }
    }

    plusPercent(plus) {
        if (!plus) {
            return 0;
        }
        return ((plus * 100) / 20);
    }

    attributePercent(value) {
        if (!value) {
            return 0;
        }
        return ((value * 100) / 32).toFixed(1);
    }

    toggleFlip() {
        if (this.hitTween) {
            this.hitTween.stop();
        }
        if (!this.isDragging && !this.isFlipping) {
            this.isFlipping = true;
            if (!this.isFlipped) {
                this.lastX = this.x;
                this.lastY = this.y;
                // this.scene.children.bringToTop(this);
                this.setDepth(10);
            } else {
                this.showFront();
            }

            this.updatePlusItem();
            this.updateShieldItem();
            this.updateLuckItem();

            this.plusText.setVisible(this.isFlipped);

            if (this.deck && this.deck.isEnemyDeck) {
                this.healthBar.setVisible(this.isFlipped);
            } else {
                this.healthBar.setVisible(false);
            }

            this.scene.tweens.add({
                targets: this.card,
                scaleX: this.cardScale * 0.01,
                ease: 'Linear',
                duration: 150,
                onComplete: () => {

                    if (this.isFlipped) {
                        this.ressource.setScale(1);
                        this.ressource.setAlpha(1);
                    } else {
                        this.ressource.setScale(2.5);
                        this.ressource.setAlpha(0.1);
                    }

                    this.background.setVisible(!this.isFlipped);
                    this.scene.tweens.add({
                        targets: this.card,
                        scaleX: this.isFlipped ? this.cardScale : this.cardScale * 2.5,
                        scaleY: this.isFlipped ? this.cardScale : this.cardScale * 2.5,
                        ease: 'Linear',
                        duration: 150,
                        onComplete: () => {
                            
                            this.isFlipped = !this.isFlipped;
                            this.isFlipping = false;
            
                            if (this.isFlipped) {
                                this.showBack();
                            }
                        }
                    });
                    this.scene.tweens.add({
                        targets: this,
                        x: this.isFlipped ? this.lastX : 0,
                        y: this.isFlipped ? this.lastY : 0,
                        ease: 'Linear',
                        duration: 150,
                    })
                }
            });
        }
    }

    showFront() {
        this.plusLabelText.setVisible(false);
        this.plusIncreaseText.setVisible(false);
        this.plusValueText.setVisible(false);
        this.plusButtonEnhance.setVisible(false);
        this.shieldButtonEnhance.setVisible(false);
        this.luckButtonEnhance.setVisible(false);
        this.riskLabelText.setVisible(false);
        this.riskValueText.setVisible(false);
        this.riskIncreaseText.setVisible(false);
        this.luckLabelText.setVisible(false);
        this.luckValueText.setVisible(false);
        this.luckIncreaseText.setVisible(false);
        this.strengthLabelText.setVisible(false);
        this.strengthIncreaseText.setVisible(false);
        this.strengthValueText.setVisible(false);
        this.strengthButtonEnhance.setVisible(false);
        this.speedLabelText.setVisible(false);
        this.speedIncreaseText.setVisible(false);
        this.speedValueText.setVisible(false);
        this.speedButtonEnhance.setVisible(false);
        this.healthLabelText.setVisible(false);
        this.healthIncreaseText.setVisible(false);
        this.healthValueText.setVisible(false);
        this.healthButtonEnhance.setVisible(false);
        this.criticalLabelText.setVisible(false);
        this.criticalIncreaseText.setVisible(false);
        this.criticalValueText.setVisible(false);
        this.criticalButtonEnhance.setVisible(false);
        this.plusItem.setVisible(false);
        this.plusItemQuantityText.setVisible(false);
        this.shieldItem.setVisible(false);
        this.shieldItemQuantityText.setVisible(false)
        this.luckItem.setVisible(false);
        this.luckItemQuantityText.setVisible(false)
        this.goLeft.setVisible(false);
        this.goRight.setVisible(false);
        this.healthBar.setVisible(false);
        this.footer.setVisible(false);

        if (this.dropZone && this.dropZone.deck && !this.dropZone.deck.isEnemyDeck) {
            this.dropZone.deck.orderCards();
        } else {
            this.setDepth(6);
        }
    }

    showBack() {
        this.plusLabelText.setVisible(true);
        this.plusIncreaseText.setVisible(true);
        this.plusValueText.setVisible(true);
        this.plusButtonEnhance.setVisible(true);
        this.shieldButtonEnhance.setVisible(true);
        this.luckButtonEnhance.setVisible(true);
        this.riskLabelText.setVisible(true);
        this.riskValueText.setVisible(true);
        this.riskIncreaseText.setVisible(true);
        this.luckLabelText.setVisible(true);
        this.luckValueText.setVisible(true);
        this.luckIncreaseText.setVisible(true);
        this.strengthLabelText.setVisible(true);
        this.strengthIncreaseText.setVisible(true);
        this.strengthValueText.setVisible(true);
        this.strengthButtonEnhance.setVisible(true);
        this.speedLabelText.setVisible(true);
        this.speedIncreaseText.setVisible(true);
        this.speedValueText.setVisible(true);
        this.speedButtonEnhance.setVisible(true);
        this.healthLabelText.setVisible(this.key.startsWith('sword-'));
        this.healthIncreaseText.setVisible(this.key.startsWith('sword-'));
        this.healthValueText.setVisible(this.key.startsWith('sword-'));
        this.healthButtonEnhance.setVisible(this.key.startsWith('sword-'));
        this.criticalLabelText.setVisible(this.key.startsWith('sword-'));
        this.criticalIncreaseText.setVisible(this.key.startsWith('sword-'));
        this.criticalValueText.setVisible(this.key.startsWith('sword-'));
        this.criticalButtonEnhance.setVisible(this.key.startsWith('sword-'));
        this.footer.setVisible(true);
        this.plusItem.setVisible(true);
        this.plusItemQuantityText.setVisible(true);
        this.shieldItem.setVisible(true);
        this.shieldItemQuantityText.setVisible(true);
        this.luckItem.setVisible(true);
        this.luckItemQuantityText.setVisible(true);
        this.goLeft.setVisible(true);
        this.goRight.setVisible(true);
    }

    updatePlusItem() {
        if (this.plusItemQuantityText.scene) {
            const quantity = this.getPlusItemQuantity();
            this.plusItemQuantityText.setText(quantity);
            this.plusButtonEnhance.setNotEnoughRessources(quantity === 0);
            this.strengthButtonEnhance.setNotEnoughRessources(quantity === 0);
            this.speedButtonEnhance.setNotEnoughRessources(quantity === 0);
            this.healthButtonEnhance.setNotEnoughRessources(quantity === 0);
            this.criticalButtonEnhance.setNotEnoughRessources(quantity === 0);
        }
    }

    updateChance() {
        let luck = 0;
        if (this.scene.player.isServantActive) {
            luck += LUCK_PERCENT;
        }
        if (this.luck) {
            luck += LUCK_PERCENT;
        }
        const chance = this.calculateChance();
        const percent = (this.shield > 0 ? 0 : (100 - chance < 0 ? 0 : 100 - chance));
        this.riskIncreaseText.setText(percent + '%')
        this.riskIncreaseText.setStyle(percent > 0 ? { font: "40px Arial", fill: "#FF0000" } : {
            font: "40px Arial",
            fill: "#228B22"
        });
        luck += this.scene.player.getRewardAttribute('luck');
        this.luckIncreaseText.setText(luck + '%')
    }

    updateShieldItem() {
        if (this.shieldItemQuantityText.scene) {
            const quantity = this.getShieldItemQuantity();
            this.shieldItemQuantityText.setText(quantity);
            this.shieldButtonEnhance.setNotEnoughRessources(quantity === 0);
        }
    }

    updateLuckItem() {
        if (this.luckItemQuantityText.scene) {
            const quantity = this.getLuckItemQuantity();
            this.luckItemQuantityText.setText(quantity);
            this.luckButtonEnhance.setNotEnoughRessources(quantity === 0);
        }
    }

    getPlusItemQuantity() {
        return this.inventory.getItemQuantity('plus-' + this.tier);
    }

    getShieldItemQuantity() {
        return this.inventory.getItemQuantity('shield-' + this.tier)
    }

    getLuckItemQuantity() {
        return this.inventory.getItemQuantity('luck')
    }

    cardAddedToDeck(deck) {
        this.healthBar.setVisible(deck.isEnemyDeck);
    }

    cardRemovedFromDeck(deck) {
        this.healthBar.setVisible(false);
    }

    registerEvents() {
        this.strengthButtonEnhance.events.on('complete', () => {
            if (!this.strengthButtonEnhance.isDisabled && !this.isDestroyed) {
                this.strengthButtonEnhance.setBusy(true);
                const smoke = this.scene.add.sprite(this.strengthIncreaseText.x - 40, this.strengthIncreaseText.y + 25, 'smoke-48');
                smoke.setOrigin(0.5, 0.5);
                smoke.setScale(0.5);
                smoke.setDepth(11);
                smoke.anims.play("smoke-48", false).once('animationcomplete', (anim) => {
                    smoke.destroy()
                });

                if (this.getPlusItemQuantity() > 0) {
                    this.inventory.removeItem('plus-' + this.tier, 1);

                    let chance = 0;
                    const random = Phaser.Math.Between(1, 100);
                    const result = 50 >= random;
                    if (result) {
                        const attributeList = new Array();
                        for (let i = 0; i <= 31; i++) {
                            attributeList.push(i);
                        }
                        this.strengthIncrease = this.listChanceDouble(attributeList, 40000.0, chance);
                    } else {
                        // fail
                    }
                }
                this.scene.time.delayedCall(this.enhanceDelay, () => {
                    this.strengthButtonEnhance.setBusy(false);
                });
            }
        });

        this.speedButtonEnhance.events.on('complete', () => {
            if (!this.speedButtonEnhance.isDisabled && !this.isDestroyed) {
                this.speedButtonEnhance.setBusy(true);
                const smoke = this.scene.add.sprite(this.speedIncreaseText.x - 40, this.speedIncreaseText.y + 25, 'smoke-48');
                smoke.setOrigin(0.5, 0.5);
                smoke.setScale(0.5);
                smoke.setDepth(11);
                smoke.anims.play("smoke-48", false).once('animationcomplete', (anim) => {
                    smoke.destroy()
                });

                if (this.getPlusItemQuantity() > 0) {
                    this.inventory.removeItem('plus-' + this.tier, 1);
                    this.updatePlusItem();

                    let chance = 0;
                    const random = Phaser.Math.Between(1, 100);
                    const result = 50 >= random;
                    if (result) {
                        const attributeList = new Array();
                        for (let i = 0; i <= 31; i++) {
                            attributeList.push(i);
                        }
                        this.speedIncrease = this.listChanceDouble(attributeList, 40000.0, chance);
                    } else {
                        // fail
                    }
                }
                this.scene.time.delayedCall(this.enhanceDelay, () => {
                    this.speedButtonEnhance.setBusy(false);
                });
            }
        });

        this.healthButtonEnhance.events.on('complete', () => {
            if (!this.healthButtonEnhance.isDisabled && !this.isDestroyed) {
                this.healthButtonEnhance.setBusy(true);
                const smoke = this.scene.add.sprite(this.healthIncreaseText.x - 40, this.healthIncreaseText.y + 25, 'smoke-48');
                smoke.setOrigin(0.5, 0.5);
                smoke.setScale(0.5);
                smoke.setDepth(11);
                smoke.anims.play("smoke-48", false).once('animationcomplete', (anim) => {
                    smoke.destroy()
                });

                if (this.getPlusItemQuantity() > 0) {
                    this.inventory.removeItem('plus-' + this.tier, 1);
                    this.updatePlusItem();

                    let chance = 0;
                    const random = Phaser.Math.Between(1, 100);
                    const result = 50 >= random;
                    if (result) {
                        const attributeList = new Array();
                        for (let i = 0; i <= 31; i++) {
                            attributeList.push(i);
                        }
                        this.healthIncrease = this.listChanceDouble(attributeList, 40000.0, chance);
                    } else {
                        // fail
                    }
                }
                this.scene.time.delayedCall(this.enhanceDelay, () => {
                    this.healthButtonEnhance.setBusy(false);
                });
            }
        });

        this.criticalButtonEnhance.events.on('complete', () => {
            if (!this.criticalButtonEnhance.isDisabled && !this.isDestroyed) {
                this.criticalButtonEnhance.setBusy(true);
                const smoke = this.scene.add.sprite(this.criticalIncreaseText.x - 40, this.criticalIncreaseText.y + 25, 'smoke-48');
                smoke.setOrigin(0.5, 0.5);
                smoke.setScale(0.5);
                smoke.setDepth(11);
                smoke.anims.play("smoke-48", false).once('animationcomplete', (anim) => {
                    smoke.destroy()
                });

                if (this.getPlusItemQuantity() > 0) {
                    this.inventory.removeItem('plus-' + this.tier, 1);
                    this.updatePlusItem();

                    let chance = 0;
                    const random = Phaser.Math.Between(1, 100);
                    const result = 50 >= random;
                    if (result) {
                        const attributeList = new Array();
                        for (let i = 0; i <= 31; i++) {
                            attributeList.push(i);
                        }
                        this.criticalIncrease = this.listChanceDouble(attributeList, 40000.0, chance);
                    } else {
                        // fail
                    }
                }
                this.scene.time.delayedCall(this.enhanceDelay, () => {
                    this.criticalButtonEnhance.setBusy(false);
                });
            }
        });

        this.shieldButtonEnhance.events.on('complete', () => {
            if (!this.shieldButtonEnhance.isDisabled && !this.isDestroyed) {
                this.shieldButtonEnhance.setBusy(true);
                const smoke = this.scene.add.sprite(this.riskValueText.x - 40, this.riskValueText.y + 25, 'smoke-48');
                smoke.setOrigin(0.5, 0.5);
                smoke.setScale(0.5);
                smoke.setDepth(11);
                smoke.anims.play("smoke-48", false).once('animationcomplete', (anim) => {
                    smoke.destroy()
                });

                if (this.getPlusItemQuantity() > 0) {
                    this.inventory.removeItem('shield-' + this.tier, 1);
                    this.updateShieldItem();
                    this.shield++;
                };
                this.scene.time.delayedCall(this.enhanceDelay, () => {
                    this.shieldButtonEnhance.setBusy(false);
                });
            }
        });

        this.luckButtonEnhance.events.on('complete', () => {
            if (!this.luckButtonEnhance.isDisabled && !this.isDestroyed) {
                this.luckButtonEnhance.setBusy(true);
                const smoke = this.scene.add.sprite(this.luckValueText.x - 40, this.luckValueText.y + 25, 'smoke-48');
                smoke.setOrigin(0.5, 0.5);
                smoke.setScale(0.5);
                smoke.setDepth(11);
                smoke.anims.play("smoke-48", false).once('animationcomplete', (anim) => {
                    smoke.destroy()
                });

                if (this.getLuckItemQuantity() > 0) {
                    this.inventory.removeItem('luck', 1);
                    this.updateLuckItem();
                    this.luck++;
                };
                this.scene.time.delayedCall(this.enhanceDelay, () => {
                    this.luckButtonEnhance.setBusy(false);
                });
            }
        });

        this.plusButtonEnhance.events.on('complete', () => {
            if (!this.plusButtonEnhance.isDisabled && !this.isDestroyed) {
                this.plusButtonEnhance.setBusy(true);
                const smoke = this.scene.add.sprite(this.plusValueText.x - 40, this.plusValueText.y + 25, 'smoke-48');
                smoke.setOrigin(0.5, 0.5);
                smoke.setScale(0.5);
                smoke.setDepth(11);
                smoke.anims.play("smoke-48", false).once('animationcomplete', (anim) => {
                    smoke.destroy()
                });

                if (this.getPlusItemQuantity() > 0) {

                    this.inventory.removeItem('plus-' + this.tier, 1);
                    this.updatePlusItem();

                    if (this.luck) {
                        this.luck--;
                    }
                    this.updateLuckItem();
                    
                    const chance = this.calculateChance();
                    const random = Phaser.Math.Between(1, 100);
                    const result = chance >= random;
                    
                    console.log('chance', random, chance, result)
                    
                    if (result) {

                        this.plus++;

                        if (this.ressource.texture.key.indexOf('sword-') > -1) {
                            this.ressource.setTexture('sword-' + (this.plus + 1));
                        }

                    } else if (this.shield > 0) {
                        this.shield--;
                        this.plus = Phaser.Math.Between(0, this.plus-1);
                    } else {
                        this.isDestroyed = true;
                        this.plus = 0;

                        if (this.deck) {
                            this.deck.removeCard(this);
                        }

                        this.scene.player.removeCard(this.name);

                        if (this.dropZone) {
                            this.dropZone.cardRemoved();
                        }

                        const fire01 = this.scene.add.sprite(0, -50, 'fire-9');
                        fire01.setOrigin(0.5, 0.5);
                        fire01.setScale(3);
                        fire01.setDepth(11);
                        fire01.anims.play('fire-9', false).once('animationcomplete', (anim) => {
                            fire01.destroy()
                            const fire02 = this.scene.add.sprite(50, -100, 'fire-8');
                            fire02.setOrigin(0.5, 0.5);
                            fire02.setScale(4);
                            fire02.setDepth(11);
                            fire02.anims.play('fire-8', false).once('animationcomplete', (anim) => {
                                fire02.destroy()
                            });
                        
                            this.scene.tweens.add({
                                targets: [this],
                                alpha: 0.1,
                                yoyo: false,
                                repeat: 0,
                                duration: 500,
                                ease: 'Sine.easeInOut',
                                onComplete: () => {
                                    this.card.setVisible(false);
                                },
                            });
                        });

                        /* destroyParticles.visible = false;
                        this.mask = new Phaser.Display.Masks.BitmapMask(this.scene, destroyParticles);
                        destroyEmitter.setPosition(0, this.height / 2); */
                        
                        this.scene.time.delayedCall(1000, () => {
                            // destroyEmitter.stop();
                        });
                        this.scene.time.delayedCall(1500, () => {
                            this.list.forEach((child) => {
                                child.setVisible(false);
                                child.destroy();
                            });
                            this.setVisible(false);
                            this.destroy(true);
                        })
                    }
                }
                this.scene.time.delayedCall(this.enhanceDelay, () => {
                    this.plusButtonEnhance.setBusy(false);
                });
            }
        });
    }

    calculateChance() {

        let chance = 0;
        if (this.luck > 0) {
            chance += LUCK_PERCENT;
        }
        if (this.scene.player.isServantActive) {
            chance += LUCK_PERCENT;
        }
        
        if (this.plus < 4) {
            chance += this.bitwiseValues(840832008)[this.plus];
        } else if (this.plus < 8) {
            chance += this.bitwiseValues(134744072)[this.plus - 4];
        } else if (this.plus < 12) {
            chance += this.bitwiseValues(134744072)[this.plus - 8];
        } else if (this.plus >= 12) {
            chance += this.bitwiseValues(134744072)[3];
        }

        if (this.plus < 4) {
            chance += this.bitwiseValues(420744970)[this.plus];
        } else if (this.plus < 8) {
            chance += this.bitwiseValues(168430090)[this.plus - 4];
        } else if (this.plus < 12) {
            chance += this.bitwiseValues(168101125)[this.plus - 8];
        } else {
            chance += this.bitwiseValues(168101125)[3];
        }

        // start help
        if (this.plus == 0) {
            chance += 10;
        } else if (this.plus == 1) {
            chance += 5;
        }

        // check rewards
        const additionalLuck = this.scene.player.getRewardAttribute('luck');
        console.log('additionalLuck', additionalLuck, chance)
        return chance + additionalLuck;
    }

    listChanceDouble(items, divider, chance) {
		let result = 0;
		const random = Phaser.Math.FloatBetween(0, 1)/*  - (chance * 0.00005) */;
		/* if (random > 0.35) {
			return 0;
        } */
		let ff = 0;
		for (let i = 0; i < items.length; i++) {
			ff += (items.length / divider) * (i + 1);
			if (random < ff) {
				result = items[items.length - 1 - i];
				break;
			}
		}
		return result;
	}

    bitcount(value) {
        let count = 0;
        for (; value != 0; value >>= 1) {
            count++;
        }
        return Math.ceil(count / 8.0);
    }

    bitwiseValues(value) {
        const count = this.bitcount(value);
        let result = new Array(count);
        for (let i = 0; i < count; i++) {
            result[count - 1 - i] = (value >> i * 8) & 0xff;
        }
        return result;
    }
}
