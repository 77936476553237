import InventoryItem from "./InventoryItem";
import UIBaseScene from "./UIBaseScene.js";

export default class InventoryScene extends UIBaseScene {
    constructor() {
        super("InventoryScene");
        this.rows = 0;
        this.inventorySlots = [];
    }
    init(data) {
        let { mainScene } = data;
        this.mainScene = mainScene;
        this.inventory = mainScene.player.inventory;
        this.maxColumns = this.inventory.maxColumns;
        this.maxRows = this.inventory.maxRows;
        this.inventory.subscribe(() => this.refresh());
    }

    destroyInventorySlot(inventorySlot) {
        if (inventorySlot.item) inventorySlot.item.destroy();
        if (inventorySlot.quantityText) inventorySlot.quantityText.destroy();
        inventorySlot.destroy();
    }

    refresh() {
        this.inventorySlots.forEach((s) => this.destroyInventorySlot(s));
        this.inventorySlots = [];
        for (let index = 0; index < this.maxColumns * this.rows; index++) {
            const x = this.margin + this.tileSize + ((index % this.maxColumns) * (this.tileSize + this.margin));
            const y = this.margin + this.tileSize + (Math.floor(index / this.maxColumns) * (this.tileSize + this.margin));
            const item = this.inventory.getItem(index);
            const inventorySlot = new InventoryItem(this, x, y, item ? item.key : '');
            inventorySlot.setQuantity(item ? item.quantity : 0);
            this.inventorySlots.push(inventorySlot);
        }
        this.updateSelected();
    }

    updateSelected() {
        for (let index = 0; index < this.maxColumns; index++) {
            if (this.inventorySlots[index]) {
                this.inventorySlots[index].tint = this.inventory.selected == index ? 0xffff00 : 0xffffff;
            }
        }
    }

    create() {
        this.refresh();
    }

    open() {
        this.rows = this.rows === 0 ? this.maxRows : 0;
        this.refresh();
    }

    render() {
        myGame.debug.text(myGame.time.fps, 5, 14, '#00ff00');
    }
}