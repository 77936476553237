import MainScene from "./MainScene";

export default class Servant extends Phaser.GameObjects.Container {

    public sprite: Phaser.GameObjects.Sprite;
    public events: Phaser.Events.EventEmitter = new Phaser.Events.EventEmitter();
    public frameTime: number = 0;
    public direction: number = 0;
    public speed: number = 10;
    public mode: string = 'walk';

    constructor(scene: MainScene, x: number, y: number) {

        const sprite = scene.add.sprite(0, 0, 'walk-0');

        super(scene, x, y, [sprite]);

        this.scene = scene;
        this.sprite = sprite;

        scene.anims.create({ key: 'walk-0', frames: scene.anims.generateFrameNumbers('walk-0', { start: 0, end: 7 }), frameRate: 7, repeat: -1 });
        scene.anims.create({ key: 'walk-1', frames: scene.anims.generateFrameNumbers('walk-1', { start: 0, end: 7 }), frameRate: 7, repeat: -1 });
        scene.anims.create({ key: 'walk-2', frames: scene.anims.generateFrameNumbers('walk-2', { start: 0, end: 7 }), frameRate: 7, repeat: -1 });
        scene.anims.create({ key: 'walk-3', frames: scene.anims.generateFrameNumbers('walk-3', { start: 0, end: 7 }), frameRate: 7, repeat: -1 });
        scene.anims.create({ key: 'walk-4', frames: scene.anims.generateFrameNumbers('walk-4', { start: 0, end: 7 }), frameRate: 7, repeat: -1 });
        scene.anims.create({ key: 'walk-5', frames: scene.anims.generateFrameNumbers('walk-5', { start: 0, end: 7 }), frameRate: 7, repeat: -1 });
        scene.anims.create({ key: 'walk-6', frames: scene.anims.generateFrameNumbers('walk-6', { start: 0, end: 7 }), frameRate: 7, repeat: -1 });
        scene.anims.create({ key: 'walk-7', frames: scene.anims.generateFrameNumbers('walk-7', { start: 0, end: 7 }), frameRate: 7, repeat: -1 });

        scene.anims.create({ key: 'attack-0', frames: scene.anims.generateFrameNumbers('attack-0', { start: 0, end: 12 }), frameRate: 7, repeat: -1 });

        this.scene.add.existing(this);
        this.scene.events.on('update', this.update, this);
    }

    static preload(scene){
        scene.load.spritesheet('walk-0', 'assets/sprites/servant/walk-0.png', { frameWidth: 256, frameHeight: 256 });
        scene.load.spritesheet('walk-1', 'assets/sprites/servant/walk-45.png', { frameWidth: 256, frameHeight: 256 });
        scene.load.spritesheet('walk-2', 'assets/sprites/servant/walk-90.png', { frameWidth: 256, frameHeight: 256 });
        scene.load.spritesheet('walk-3', 'assets/sprites/servant/walk-135.png', { frameWidth: 256, frameHeight: 256 });
        scene.load.spritesheet('walk-4', 'assets/sprites/servant/walk-180.png', { frameWidth: 256, frameHeight: 256 });
        scene.load.spritesheet('walk-5', 'assets/sprites/servant/walk-225.png', { frameWidth: 256, frameHeight: 256 });
        scene.load.spritesheet('walk-6', 'assets/sprites/servant/walk-270.png', { frameWidth: 256, frameHeight: 256 });
        scene.load.spritesheet('walk-7', 'assets/sprites/servant/walk-315.png', { frameWidth: 256, frameHeight: 256 });
        scene.load.spritesheet('attack-0', 'assets/sprites/servant/attack-0.png', { frameWidth: 768, frameHeight: 768 });
    }

    start() {
        this.sprite.anims.play("walk-" + this.direction, true);
    }

    changeDirection() {
        if (this.sprite.x < -(this.scene.sys.game.canvas.width / 2)) {
            this.direction = 0;
        } else if (this.sprite.x > (this.scene.sys.game.canvas.width / 2)) {
            this.direction = 4;
        } if (this.sprite.y < -(this.scene.sys.game.canvas.height / 2)) {
            this.direction = 6;
        } else if (this.sprite.y > (this.scene.sys.game.canvas.height / 2)) {
            this.direction = 2;
        } else {
            const change = Phaser.Math.Between(1, 10);
            if (change === 1) {
                let start = this.direction - 1;
                let end = this.direction + 1;
                if (start < 0) {
                    start = 0;
                }
                if (end > 7) {
                    start = 7;
                }
                this.direction = Phaser.Math.Between(start, end);
                this.mode = 'walk';
            } else if (change === 2) {
                this.direction = 0;
                this.mode = 'attack';
            }
        }
        this.sprite.anims.play(this.mode + '-' + this.direction, true);
    }

    update(time, delta) {
        this.frameTime += delta;
        const every = 600;
        while (this.frameTime > every) {
            this.frameTime -= every;

            switch(this.direction) {
                case 0:
                    this.sprite.x += this.speed;
                    break;
                case 1:
                    this.sprite.x += this.speed;
                    this.sprite.y -= this.speed;
                    break;
                case 2:
                    this.sprite.y -= this.speed;
                    break;
                case 3:
                    this.sprite.x -= this.speed;
                    this.sprite.y -= this.speed;
                    break;
                case 4:
                    this.sprite.x -= this.speed;
                    break;
                case 5:
                    this.sprite.x -= this.speed;
                    this.sprite.y += this.speed;
                    break;
                case 6:
                    this.sprite.y += this.speed;
                    break;
                case 7:
                    this.sprite.x += this.speed;
                    this.sprite.y += this.speed;
                    break;
            }
            this.changeDirection();
        }
    }
}