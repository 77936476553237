import Player from "./Player";
import Crafting from "./Crafting.js"
import LootableResource from "./LootableResource.js";
import Card from "./Card.js";
import CardEnemy from "./CardEnemy.js";
import DeckEnemy from "./DeckEnemy";
import IDeckData from "./data/IDeckData.js";
import Deck from "./Deck";
import FloatingNumbersPlugin from "./FloatingNumbersPlugin";
import CardBase from "./CardBase.js";
import CardOffline from "./CardOffline";
import Servant from "./Servant";

export default class MainScene extends Phaser.Scene {

    public player: Player = new Player();
    public map: Phaser.GameObjects.Image;
    public hand: Phaser.GameObjects.Sprite;
    public crafting: Crafting = new Crafting({ mainscene: this });
    public isDraggingCard: boolean = false;
    public decksMap: { [key: string]: IDeckData };
    public floatingNumbers: FloatingNumbersPlugin;
    public offline: CardOffline;
    public frameTime: number = 0;
    public servant: Servant;

    constructor() {
        super("MainScene");

        this.decksMap = {
            'deck-1': {
                key: 'wood-${tier}',
                dropOptions: [
                    { key: 'wood-${tier}', chance: 100 },
                    { key: 'branch-${tier}', chance: 1 },
                ],
                title: 'Forest',
                x: -150,
                y: -350,
                limit: 5,
                limitTool: 'axe-',
                deckName: 'deck-1',
                isEnemy: false,
            },
            'deck-2': {
                key: 'stone-${tier}',
                dropOptions: [
                    { key: 'stone-${tier}', chance: 100 },
                    { key: 'bone-${tier}', chance: 2 },
                ],
                title: 'Mine',
                x: 150,
                y: -350,
                limit: 5,
                limitTool: 'pickaxe-',
                deckName: 'deck-2',
                isEnemy: false,
            },
            'deck-3': {
                key: 'plus-${tier}',
                dropOptions: [
                    { key: 'plus-${tier}', chance: 100 },
                    { key: 'luck', chance: 1 },
                ],
                title: 'Dungeon',
                x: 150,
                y: 220,
                limit: 3,
                limitTool: 'sword-',
                deckName: 'deck-3',
                isEnemy: false,
            },
            'deck-4': {
                key: 'plus-${tier}',
                dropOptions: [],
                title: 'Boss',
                x: -150,
                y: 220,
                limit: 1,
                limitTool: 'sword-',
                deckName: 'deck-4',
                isEnemy: true,
            },
        };
    }

    showProgress(): void {
        const progressBar = this.add.graphics();
        const progressBox = this.add.graphics();
        progressBox.fillStyle(0x222222, 0.8);
        progressBox.fillRect(240, 270, 320, 50);

        const width = this.cameras.main.width;
        const height = this.cameras.main.height;
        const loadingText = this.make.text({
            x: width / 2,
            y: height / 2 - 50,
            text: 'Loading...',
            style: {
                font: '20px monospace',
                fill: '#ffffff'
            } as any,
        });
        loadingText.setOrigin(0.5, 0.5);

        const percentText = this.make.text({
            x: width / 2,
            y: height / 2 - 5,
            text: '0%',
            style: {
                font: '18px monospace',
                fill: '#ffffff',
            } as any,
        });
        percentText.setOrigin(0.5, 0.5);

        /* const assetText = this.make.text({
            x: width / 2,
            y: height / 2 + 50,
            text: '',
            style: {
                font: '18px monospace',
                fill: '#ffffff',
            } as any,
        });
        assetText.setOrigin(0.5, 0.5); */

        this.load.on('progress', (value: number) => {
            percentText.setText((value * 100).toFixed() + '%');
            progressBar.clear();
            progressBar.fillStyle(0xffffff, 1);
            progressBar.fillRect(250, 280, 300 * value, 30);
        });
        this.load.on('fileprogress', (file) => {
            // assetText.setText('Loading asset: ' + file.key);
        });
        this.load.on('complete', () => {
            progressBar.destroy();
            progressBox.destroy();
            loadingText.destroy();
            percentText.destroy();
            // assetText.destroy();
        });
    }

    preload() {
        Servant.preload(this);

        this.showProgress();

        this.load.image('map', 'assets/images/map.png');
        this.load.image('card-empty', 'assets/images/card-empty.png');
        this.load.image('bag', 'assets/images/bag.png');
        this.load.image('rewards', 'assets/images/rewards.png');
        this.load.image('slime', 'assets/images/slime.png');
        this.load.image('slime-button', 'assets/images/slime-button.png');
        this.load.image('craft', 'assets/images/craft.png');
        this.load.image('card', 'assets/images/card.png');
        this.load.image('dice', 'assets/images/dice.png');
        this.load.image('luck', 'assets/images/luck.png');
        this.load.image('shield-blue', 'assets/images/shield-blue.png');
        this.load.image('shield-green', 'assets/images/shield-green.png');
        this.load.image('spark', 'assets/particles/blue.png');
        this.load.image('smoke', 'assets/particles/smoke.png');
        this.load.atlas('flares', 'assets/particles/flares.png', 'assets/particles/flares.json');

        this.load.spritesheet('hand', 'assets/sprites/hand.png', { frameWidth: 600, frameHeight: 600 });

        this.load.plugin('rexshakepositionplugin', 'assets/rexshakepositionplugin.min.js', true);
        this.load.plugin('rexawaytimeplugin', 'assets/rexawaytimeplugin.min.js', true);
        // this.load.plugin('cryptojs', 'assets/cryptojs.min.js', true);

        this.load.image('axe-1', 'assets/images/axe-1.png');
        this.load.image('axe-2', 'assets/images/axe-2.png');
        this.load.image('axe-3', 'assets/images/axe-3.png');
        this.load.image('axe-4', 'assets/images/axe-4.png');
        this.load.image('axe-5', 'assets/images/axe-5.png');
        this.load.image('axe-6', 'assets/images/axe-6.png');
        this.load.image('pickaxe-1', 'assets/images/pickaxe-1.png');
        this.load.image('pickaxe-2', 'assets/images/pickaxe-2.png');
        this.load.image('pickaxe-3', 'assets/images/pickaxe-3.png');
        this.load.image('pickaxe-4', 'assets/images/pickaxe-4.png');
        this.load.image('pickaxe-5', 'assets/images/pickaxe-5.png');
        this.load.image('pickaxe-6', 'assets/images/pickaxe-6.png');
        this.load.image('wood-1', 'assets/images/items/wood-1.png');
        this.load.image('wood-2', 'assets/images/items/wood-2.png');
        this.load.image('wood-3', 'assets/images/items/wood-3.png');
        this.load.image('wood-4', 'assets/images/items/wood-4.png');
        this.load.image('wood-5', 'assets/images/items/wood-5.png');
        this.load.image('wood-6', 'assets/images/items/wood-6.png');

        this.load.image('stone-1', 'assets/images/items/stone-1.png');
        this.load.image('stone-2', 'assets/images/items/stone-2.png');
        this.load.image('stone-3', 'assets/images/items/stone-3.png');
        this.load.image('stone-4', 'assets/images/items/stone-4.png');
        this.load.image('stone-5', 'assets/images/items/stone-5.png');
        this.load.image('stone-6', 'assets/images/items/stone-6.png');

        this.load.image('coal-1', 'assets/images/items/coal-1.png');
        this.load.image('coal-2', 'assets/images/items/coal-2.png');
        this.load.image('coal-3', 'assets/images/items/coal-3.png');
        this.load.image('coal-4', 'assets/images/items/coal-4.png');
        this.load.image('coal-5', 'assets/images/items/coal-5.png');
        this.load.image('coal-6', 'assets/images/items/coal-6.png');

        this.load.image('metal-1', 'assets/images/items/metal-1.png');
        this.load.image('metal-2', 'assets/images/items/metal-2.png');
        this.load.image('metal-3', 'assets/images/items/metal-3.png');
        this.load.image('metal-4', 'assets/images/items/metal-4.png');
        this.load.image('metal-5', 'assets/images/items/metal-5.png');
        this.load.image('metal-6', 'assets/images/items/metal-6.png');
        
        this.load.image('branch-1', 'assets/images/items/branch-1.png');
        this.load.image('branch-2', 'assets/images/items/branch-2.png');
        this.load.image('branch-3', 'assets/images/items/branch-3.png');
        this.load.image('branch-4', 'assets/images/items/branch-4.png');
        this.load.image('branch-5', 'assets/images/items/branch-5.png');
        this.load.image('branch-6', 'assets/images/items/branch-6.png');

        this.load.image('bone-1', 'assets/images/items/bone-1.png');
        this.load.image('bone-2', 'assets/images/items/bone-2.png');
        this.load.image('bone-3', 'assets/images/items/bone-3.png');
        this.load.image('bone-4', 'assets/images/items/bone-4.png');
        this.load.image('bone-5', 'assets/images/items/bone-5.png');
        this.load.image('bone-6', 'assets/images/items/bone-6.png');

        this.load.image('plus-1', 'assets/images/items/plus-1.png');
        this.load.image('plus-2', 'assets/images/items/plus-2.png');
        this.load.image('plus-3', 'assets/images/items/plus-3.png');
        this.load.image('plus-4', 'assets/images/items/plus-4.png');
        this.load.image('plus-5', 'assets/images/items/plus-5.png');
        this.load.image('plus-6', 'assets/images/items/plus-6.png');

        this.load.image('sword-1', 'assets/images/swords/sword-1.png');
        this.load.image('sword-2', 'assets/images/swords/sword-2.png');
        this.load.image('sword-3', 'assets/images/swords/sword-3.png');
        this.load.image('sword-4', 'assets/images/swords/sword-4.png');
        this.load.image('sword-5', 'assets/images/swords/sword-5.png');
        this.load.image('sword-6', 'assets/images/swords/sword-6.png');
        this.load.image('sword-7', 'assets/images/swords/sword-7.png');
        this.load.image('sword-8', 'assets/images/swords/sword-8.png');
        this.load.image('sword-9', 'assets/images/swords/sword-9.png');
        this.load.image('sword-10', 'assets/images/swords/sword-10.png');
        this.load.image('sword-11', 'assets/images/swords/sword-11.png');
        this.load.image('sword-12', 'assets/images/swords/sword-12.png');
        this.load.image('sword-13', 'assets/images/swords/sword-13.png');
        this.load.image('sword-14', 'assets/images/swords/sword-14.png');
        this.load.image('sword-15', 'assets/images/swords/sword-15.png');
        this.load.image('sword-16', 'assets/images/swords/sword-16.png');
        this.load.image('sword-17', 'assets/images/swords/sword-17.png');
        this.load.image('sword-18', 'assets/images/swords/sword-18.png');
        this.load.image('sword-19', 'assets/images/swords/sword-19.png');
        this.load.image('sword-20', 'assets/images/swords/sword-20.png');

        this.load.image('shield-1', 'assets/images/items/shield-1.png');
        this.load.image('shield-2', 'assets/images/items/shield-2.png');
        this.load.image('shield-3', 'assets/images/items/shield-3.png');
        this.load.image('shield-4', 'assets/images/items/shield-4.png');
        this.load.image('shield-5', 'assets/images/items/shield-5.png');
        this.load.image('shield-6', 'assets/images/items/shield-6.png');

        this.load.image('monster-1', 'assets/images/monsters/monster-1.png');
        this.load.image('monster-2', 'assets/images/monsters/monster-2.png');
        this.load.image('monster-3', 'assets/images/monsters/monster-3.png');
        this.load.image('monster-4', 'assets/images/monsters/monster-4.png');
        this.load.image('monster-5', 'assets/images/monsters/monster-5.png');
        this.load.image('monster-6', 'assets/images/monsters/monster-6.png');
        this.load.image('monster-7', 'assets/images/monsters/monster-7.png');
        this.load.image('monster-8', 'assets/images/monsters/monster-8.png');
        this.load.image('monster-9', 'assets/images/monsters/monster-9.png');
        this.load.image('monster-10', 'assets/images/monsters/monster-10.png');
        this.load.image('monster-11', 'assets/images/monsters/monster-11.png');
        this.load.image('monster-12', 'assets/images/monsters/monster-12.png');
        this.load.image('monster-13', 'assets/images/monsters/monster-13.png');
        this.load.image('monster-14', 'assets/images/monsters/monster-14.png');
        this.load.image('monster-15', 'assets/images/monsters/monster-15.png');
        this.load.image('monster-16', 'assets/images/monsters/monster-16.png');
        this.load.image('monster-17', 'assets/images/monsters/monster-17.png');
        this.load.image('monster-18', 'assets/images/monsters/monster-18.png');
        this.load.image('monster-19', 'assets/images/monsters/monster-19.png');
        this.load.image('monster-20', 'assets/images/monsters/monster-20.png');
        this.load.image('monster-21', 'assets/images/monsters/monster-21.png');
        this.load.image('monster-22', 'assets/images/monsters/monster-22.png');
        this.load.image('monster-23', 'assets/images/monsters/monster-23.png');
        this.load.image('monster-24', 'assets/images/monsters/monster-24.png');
        this.load.image('monster-25', 'assets/images/monsters/monster-25.png');

        this.load.image('reward-1', 'assets/images/rewards/reward-1.png');
        this.load.image('reward-2', 'assets/images/rewards/reward-2.png');
        this.load.image('reward-3', 'assets/images/rewards/reward-3.png');
        this.load.image('reward-24', 'assets/images/rewards/reward-24.png');
        this.load.image('reward-25', 'assets/images/rewards/reward-25.png');
        this.load.image('reward-26', 'assets/images/rewards/reward-26.png');
        this.load.image('reward-37', 'assets/images/rewards/reward-37.png');
        this.load.image('reward-38', 'assets/images/rewards/reward-38.png');
        this.load.image('reward-39', 'assets/images/rewards/reward-39.png');
        this.load.image('reward-92', 'assets/images/rewards/reward-93.png');
        this.load.image('reward-93', 'assets/images/rewards/reward-92.png');
        this.load.image('reward-94', 'assets/images/rewards/reward-94.png');
        this.load.image('reward-95', 'assets/images/rewards/reward-95.png');
        this.load.image('reward-96', 'assets/images/rewards/reward-96.png');
        this.load.image('reward-97', 'assets/images/rewards/reward-97.png');
        this.load.image('reward-98', 'assets/images/rewards/reward-98.png');
        this.load.image('reward-99', 'assets/images/rewards/reward-99.png');
        this.load.image('reward-100', 'assets/images/rewards/reward-100.png');
        this.load.image('reward-104', 'assets/images/rewards/reward-104.png');
        this.load.image('reward-105', 'assets/images/rewards/reward-105.png');
        this.load.image('reward-106', 'assets/images/rewards/reward-106.png');
        this.load.image('reward-110', 'assets/images/rewards/reward-110.png');
        this.load.image('reward-111', 'assets/images/rewards/reward-111.png');
        this.load.image('reward-112', 'assets/images/rewards/reward-112.png');

        this.load.spritesheet('smoke-22', 'assets/sprites/smoke-22.png', { frameWidth: 256, frameHeight: 256 });
        this.load.spritesheet('smoke-48', 'assets/sprites/smoke-48.png', { frameWidth: 256, frameHeight: 256 });
        this.load.spritesheet('smoke-57', 'assets/sprites/smoke-57.png', { frameWidth: 256, frameHeight: 256 });

        this.load.spritesheet('fire-8', 'assets/sprites/fire-8.png', { frameWidth: 256, frameHeight: 256 });
        this.load.spritesheet('fire-9', 'assets/sprites/fire-9.png', { frameWidth: 256, frameHeight: 256 });
        this.load.spritesheet('fire-30', 'assets/sprites/fire-30.png', { frameWidth: 256, frameHeight: 256 });
    }

    create() {
        this.cameras.main.setBounds(-(this.sys.game.canvas.width / 2), -(this.sys.game.canvas.height / 2), this.sys.game.canvas.width / 2, this.sys.game.canvas.height / 2, true);
        // this.cameras.main.zoom = 0.5;
        this.map = this.add.image(0, 0, 'map');
        // this.map.setScale(0.5);
        this.map.setOrigin(0.5, 0.5);

        this.player.inventory.maxColumns = Math.floor(this.sys.game.canvas.width / (128 + 16));

        this.offline = new CardOffline({ scene: this, x: 0, y: 0 });

        this.anims.create({
            key: 'hand',
            frames: this.anims.generateFrameNumbers('hand', { start: 0, end: 31 }),
            frameRate: 20,
            repeat: -1,
        });

        this.anims.create({
            key: 'smoke-22',
            frames: this.anims.generateFrameNumbers('smoke-22', { start: 0, end: 19 }),
            frameRate: 20,
            repeat: 0,
        });

        this.anims.create({
            key: 'smoke-48',
            frames: this.anims.generateFrameNumbers('smoke-48', { start: 0, end: 17 }),
            frameRate: 20,
            repeat: 0,
        });

        this.anims.create({
            key: 'fire-8',
            frames: this.anims.generateFrameNumbers('fire-8', { start: 0, end: 25 }),
            frameRate: 20,
            repeat: 0,
        });

        this.anims.create({
            key: 'fire-9',
            frames: this.anims.generateFrameNumbers('fire-9', { start: 0, end: 9 }),
            frameRate: 20,
            repeat: 0,
        });

        this.anims.create({
            key: 'fire-30',
            frames: this.anims.generateFrameNumbers('fire-30', { start: 0, end: 24 }),
            frameRate: 20,
            repeat: -1,
        });

        this.floatingNumbers = new FloatingNumbersPlugin(this);

        this.servant = new Servant(this, 0, 0);
        this.servant.setDepth(8);
        this.servant.start();
        this.servant.setVisible(false);

        // 
        Object.values(this.decksMap).forEach(deck => {
            deck.ressource = new LootableResource({
                scene: this,
                key: deck.key,
                dropOptions: deck.dropOptions,
                title: deck.title,
                x: deck.x,
                y: deck.y,
                limit: deck.limit,
                limitTool: deck.limitTool,
                deck: deck.isEnemy ? new DeckEnemy(this, deck.deckName) : new Deck(this, deck.deckName),
            });

            if (deck.isEnemy) {
                
                deck.ressource.deck.addCard(new CardEnemy({
                    scene: this,
                    x: -150,
                    y: deck.ressource.deck.getCoordinatesY(2),
                    isFlipped: true,
                    depth: 2,
                    id: 2,
                }));

                deck.ressource.deck.addCard(new CardEnemy({
                    scene: this,
                    x: -150,
                    y: deck.ressource.deck.getCoordinatesY(1),
                    isFlipped: false,
                    depth: 1,
                    id: 1,
                }));

                deck.ressource.deck.addCard(new CardEnemy({
                    scene: this,
                    x: -150,
                    y: deck.ressource.deck.getCoordinatesY(0),
                    isFlipped: false,
                    depth: 0,
                    id: 0,
                }));
            }
        });

        if (Object.keys(this.player.inventory.items).length === 0) {
            this.hand = this.add.sprite(-80, -350, 'hand');
            this.hand.setOrigin(0.5, 0.5);
            this.hand.setDepth(9);
            this.hand.anims.play("hand", false);
        }

        // 
        this.player.dataManager.cards.forEach(card => {
            const cardObject = new Card({
                scene: this,
                x: card.x,
                y: card.y,
                key: card.key,
                name: card.name,
                plus: card.plus,
                shield: card.shield,
                luck: card.luck < 0 ? 0 : card.luck,
                strengthIncrease: card.strengthIncrease,
                speedIncrease: card.speedIncrease,
                healthIncrease: card.healthIncrease,
                criticalIncrease: card.criticalIncrease,
                dropZone: null,
            });
            this.player.cards.push(cardObject);

            this.player.dataManager.decks.forEach(deck => {
                deck.cards.forEach(deckCard => {
                    if (deckCard.name === cardObject.name) {
                        cardObject.dropZone = this.decksMap[deck.name].ressource;
                        cardObject.dropZone.deck.cards.push(cardObject);
                        cardObject.deck = cardObject.dropZone.deck;
                        cardObject.setPosition(cardObject.dropZone.x + (cardObject.dropZone.deck.isEnemyDeck ? 130 : 0), cardObject.dropZone.y + ((cardObject.dropZone.deck.length - 1) * 30) + (cardObject.dropZone.deck.isEnemyDeck ? 170 : 0));
                        cardObject.dropZone.cardAdded();
                        cardObject.cardAddedToDeck(cardObject.dropZone.deck);
                    }
                });
            });
        });

        // fring fighting to front
        Object.values(this.decksMap).forEach((deck: IDeckData) => {
            if (deck.isEnemy) {
                deck.ressource.deck.cards.forEach((card: CardBase) => {
                    this.children.bringToTop(card);
                });
            }
        });

        /* this.input.on("pointermove", function (p) {
            if (!p.isDown || this.scene.isDraggingCard) return;
            camera.scrollX -= (p.x - p.prevPosition.x) / camera.zoom;
            camera.scrollY -= (p.y - p.prevPosition.y) / camera.zoom;
        }); */

        window.addEventListener('resize', () => {
            console.log('resize', window.innerWidth, window.innerHeight)
            this.sys.game.scale.resize(window.innerWidth, window.innerHeight);
            // this.cameras.main.centerOn(0, 0);
            // this.map.setOrigin(0.5, 0.5);
        });

        this.scene.launch('InventoryScene', { mainScene: this });
        this.scene.launch('CraftingScene', { mainscene: this });
        this.scene.launch('RewardScene', { mainScene: this });

        this.input.dragDistanceThreshold = 3

        this.input.on("dragstart", (pointer, gameObject) => {
            this.isDraggingCard = true;
            this.children.bringToTop(gameObject);
            if (gameObject.dropZone) {
                
                // reset health if swort is taken off
                if (gameObject.dropZone.deck.isEnemyDeck) {
                    gameObject.health = gameObject.maxHealth;
                    gameObject.healthBar.setMax(gameObject.health);
                    gameObject.healthBar.set(gameObject.health);
                    gameObject.dropZone.deck.cards.forEach((card: CardBase) => {
                        card.health = card.maxHealth;
                        card.healthBar.setMax(card.health);
                        card.healthBar.set(card.health);
                    });
                }

                gameObject.dropZone.deck.removeCard(gameObject);
                gameObject.dropZone.cardRemoved();
                gameObject.dropZone = null;
                gameObject.deck = null;
            }
        });
        this.input.on("drag", (pointer, gameObject, dragX, dragY) => {
            if (gameObject.isFlipped) return;
            gameObject.isDragging = true;
            gameObject.x = dragX;
            gameObject.y = dragY;
        });
        this.input.on("dragend", (pointer, gameObject, dropZone) => {
            this.isDraggingCard = false;
            this.time.delayedCall(100, () => {
                gameObject.isDragging = false;
            });
            this.player.updateCard(gameObject.key, gameObject.x, gameObject.y, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
        });
        this.input.on("drop", (pointer, gameObject, dropZone) => {
            if (gameObject.isDragging) {
                const cardAdded = dropZone.deck.addCard(gameObject, gameObject.key, dropZone.limitTool);
                if (cardAdded) {
                    if (dropZone.deck.isEnemyDeck) {
                        gameObject.x = dropZone.x + 130;
                        gameObject.y = dropZone.y + ((dropZone.deck.cards.length - 1) * 30) + 170;
                    } else {
                        gameObject.x = dropZone.x;
                        gameObject.y = dropZone.y + ((dropZone.deck.length - 1) * 30);
                    }

                    gameObject.dropZone = dropZone;
                    dropZone.cardAdded();
                    gameObject.deck = dropZone.deck;
                } else {
                    gameObject.x = dropZone.x + Phaser.Math.Between(-100, 100);
                    gameObject.y = dropZone.y + Phaser.Math.Between(-100, 100);
                    this.tweens.add({
                        targets: [gameObject],
                        alpha: 0.3,
                        yoyo: true,
                        repeat: 1,
                        duration: 100,
                        ease: 'Sine.easeInOut',
                    });
                }
            }
        });
    }

    update(time, delta) {
        this.frameTime += delta;
        const every = 1000;
        let wasAway = false;
        while (this.frameTime > every) {
            this.frameTime -= every;
            wasAway = true;

            this.servant.setVisible(this.player.isServantActive);
        }

        if (wasAway) {
            const awayTime = (this.plugins.get('rexawaytimeplugin') as any).awayTime;
            if (awayTime > 30000) { // 0
                if (!this.offline.isFlipped) {
                    this.offline.toggleFlip(awayTime); // 30 * 60 * 1000
                }
            }
        }
    }
}