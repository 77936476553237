import CraftingScene from "./CraftingScene";

export default class CraftingBar extends Phaser.GameObjects.Container {

    public bar: Phaser.GameObjects.Graphics;
    public step: number;
    public value: number;
    public maxValue: number;
    public events: Phaser.Events.EventEmitter = new Phaser.Events.EventEmitter();

    constructor(scene: CraftingScene, x: number, y: number, width: number, height: number) {
        const bar = new Phaser.GameObjects.Graphics(scene);

        super(scene, x, y, [bar]);

        this.width = width;
        this.height = height;
        this.maxValue = 1000;
        this.step = this.width / this.maxValue;
        
        this.bar = bar;
        this.x = x;
        this.y = y;
        this.value = 0;

        this.draw();

        scene.add.existing(this);
    }

    addToValue(amount) {
        this.value += amount;
        if (this.value > this.maxValue) {
            this.value = this.maxValue;
        }
        this.draw();
        if (this.value >= this.maxValue) {
            this.value = 0;
            this.events.emit('complete');
        }
    }

    set(value) {
        this.value = value;
        this.draw();
    }

    draw() {
        this.bar.clear();
        this.bar.fillStyle(0xffffff, 0.5);
        this.bar.fillRect(this.x + 4, this.y + 4, Math.floor(this.step * this.value), this.height);
    }
}