

import Phaser from "phaser";
import PhaserMatterCollisionPlugin from "phaser-matter-collision-plugin";
import MainScene from "./MainScene";
import InventoryScene from "./InventoryScene.js";
import CraftingScene from "./CraftingScene.js";
import RewardScene from "./RewardScene";

const pluginConfig = {
    plugin: PhaserMatterCollisionPlugin,
    key: "matterCollision" as "matterCollision",
    mapping: "matterCollision" as "matterCollision"
};

declare module "phaser" {
    interface Scene {
        [pluginConfig.mapping]: PhaserMatterCollisionPlugin;
    }
    namespace Scenes {
        interface Systems {
            [pluginConfig.key]: PhaserMatterCollisionPlugin;
        }
    }
}

const config = {
    width: window.innerWidth * 2,
    height: window.innerHeight * 2,
    backgroundColor: '#000000',
    type: Phaser.AUTO,
    resolution: window.devicePixelRatio,
    parent: 'game-container',
    scene: [MainScene, CraftingScene, RewardScene, InventoryScene],
    scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
    },
    physics: {
        default: 'matter',
        fps: 10,
        matter: {
            debug: false,
            gravity: { y: 0 },
        }
    },
    plugins: {
        scene: [pluginConfig],
    }
}

new Phaser.Game(config);