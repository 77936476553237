import DeckBase from "./DeckBase";
import MainScene from "./MainScene";

export default class DeckEnemy extends DeckBase {

    constructor(scene: MainScene, name: string) {
        super(scene, name);
        this.isEnemyDeck = true;
    }

    get length(): number {
        let result = 0;
        this.cards.forEach((card) => {
            if (card.key) {
                result++;
            }
        });
        return result;
    }

    addCard(card, key, limitTool) {
        if (key) {
            if (!this.cards.includes(card) && this.length < this.limit && key.startsWith(limitTool)) {
                this.cards.push(card);
                this.scene.player.dataManager.saveDeck(this.name, this.cards);
                card.cardAddedToDeck(this);
                return true;
            }
        } else {
            this.cards.unshift(card);
            return true;
        }
        return false;
    }

    removeCard(card) {
        this.cards = this.cards.filter((item) => {
            return item !== card;
        });
        card.cardRemovedFromDeck(this);
        this.scene.player.dataManager.saveDeck(this.name, this.cards);
    }

    moveCard(sourceIndex, targetIndex) {
        const card = this.cards[sourceIndex];
        this.scene.tweens.add({
            targets: [card],
            props: {
                y: { value: () => { return this.getCoordinatesY(targetIndex); }, ease: 'Power1' }
            },
            yoyo: false,
            repeat: 0,
            duration: 200,
            ease: 'Sine.easeInOut',
        });
        card.setDepth(targetIndex);
        return card;
    }

    getCoordinatesY(index: number): number {
        switch (index) {
            case 0:
                return 220;
            case 1:
                return 250;
            case 2:
                return 280;
        }
        return 0;
    }
}