import Card from "./Card";

export default class SpawnItem extends Phaser.GameObjects.GameObject {
    constructor(data) {
        let { scene, mainscene, key, x, y, isCard, dirStart, dirStop, radius, gravity, quantity, showSmoke } = data;

        const shadow = new Phaser.GameObjects.Ellipse(scene, x, y, 100, 50, 0x000000, 0.5);
        const ressource = new Phaser.GameObjects.Image(scene, x, y, key);
        const smoke = new Phaser.GameObjects.Sprite(scene, x + 60, y, 'smoke-22');
        
        ressource.setScale(0.5);
        shadow.setVisible(false);
        ressource.setVisible(false);
        shadow.setDepth(7);
        ressource.setDepth(7);

        super(scene);

        this.scene.add.existing(shadow);
        this.scene.add.existing(ressource);

        if (showSmoke) {
            smoke.setDepth(7);
            this.scene.add.existing(smoke);
            smoke.setOrigin(0, 0);
            smoke.anims.play("smoke-22", false).once('animationcomplete', (anim) => {
                smoke.destroy()
            });
        }

        this.scene = scene;
        this.mainscene = mainscene;
        this.key = key;
        this.isCard = isCard;
        this.isCardSpawned = false;
        this.ressource = ressource;
        this.shadow = shadow;

        this.friction = Phaser.Math.FloatBetween(0.1, 0.3);
        this.gravity = gravity || 1.0;
        this.radius = radius || 6;
        this.quantity = quantity || 1;
        this.showSmoke = showSmoke || true;

        const size = Phaser.Display.Bounds.GetBounds(ressource);
        const degree = Phaser.Math.Between(dirStart, dirStop);
        const step = (1 / 360) * degree;
        const dir = step * Math.PI * 2;
        const len = this.radius + Math.random() * 2;
        
        this.xspeed = Math.cos(dir) * len;
        this.yspeed = Math.sin(dir) * len;
        this.zspeed = 8;
        this.xx = x + size.width;
        this.yy = y + size.height;
        this.zz = 0;

        this.isMoving = false;
        this.frameTime = 0;
        this.sinceBirth = 0;

        scene.events.on('update', this.update, this);
    }

    getShadowSize() {
        return 36 / (1 + this.zz / 20);
    }

    update(time, delta) {
        this.frameTime += delta
        this.sinceBirth += delta
        if (this.frameTime > 45 && !this.isMoving) {
            this.xx += this.xspeed;
            this.yy += this.yspeed;

            var xyspeed = Math.sqrt(
                this.xspeed * this.xspeed + this.yspeed * this.yspeed
            );
            if (xyspeed > 0) {
                var mul = Math.max(0, xyspeed - this.friction) / xyspeed;
                this.xspeed *= mul;
                this.yspeed *= mul;
            }
            if (this.zz > 0) this.zspeed -= this.gravity;
            this.zz += this.zspeed;
            if (this.zz < 0) {
                if (this.zspeed < 0) this.zspeed = -this.zspeed * 0.6 - 0.7;
                if (this.zspeed < 1) {
                    this.zspeed = 0;
                    this.zz = 0;
                } else this.zz = -this.zz;
            }

            this.ressource.x = (this.xx - 64) | 0;
            this.ressource.y = (this.yy - this.zz - 75) | 0;
            this.ressource.setVisible(true);

            var r = this.getShadowSize();
            var x = this.ressource.x - r;
            var y = this.yy - r / 2;
            var w = r * 2;
            var h = r;
            var x1 = x + w / 2;
            var y1 = y + h / 2;

            this.shadow.x = x1;
            this.shadow.y = y1 - 40;
            this.shadow.setDisplaySize(w, h);
            this.shadow.setVisible(true);
            
            this.frameTime = 0;
        }
        if (this.sinceBirth > 3000) {
            if (!this.isMoving && !this.isCard) {
                this.moveTo();
            }
            if (this.isCard && !this.isCardSpawned) {
                const cardData = {
                    name: Phaser.Utils.String.UUID(),
                    key: this.key,
                    x: 0, // this.ressource.x,
                    y: 0, // this.ressource.y,
                };
                const card = new Card({
                    scene: this.mainscene,
                    x: cardData.x,
                    y: cardData.y,
                    key: cardData.key,
                    name: cardData.name,
                });

                const smoke = this.mainscene.add.sprite(cardData.x, cardData.y, 'smoke-48');
                smoke.setScale(2);
                smoke.setOrigin(0.5, 0.5);
                smoke.setDepth(6);
                smoke.anims.play("smoke-48", false).once('animationcomplete', (anim) => {
                    smoke.destroy()
                });

                this.mainscene.player.addCard(card);
                this.isCardSpawned = true;
                this.ressource.destroy();
                this.shadow.destroy();
                this.destroy();
            }
        }
    }

    moveTo() {
        this.isMoving = true;
        
        const isReward = this.key.startsWith('reward-');
        const goToX = this.scene.cameras.main._scrollX + this.scene.sys.game.canvas.width - 50;
        const goToY = this.scene.cameras.main._scrollY + (isReward ? 200 : this.scene.sys.game.canvas.height) - 100;

        this.scene.tweens.add({
            targets: [this.ressource, this.shadow],
            props: {
                x: { value: () => { return goToX; }, ease: 'Power1' },
                y: { value: () => { return goToY; }, ease: 'Power3' }
            },
            scale: 10,
            alpha: 0.3,
            duration: 500,
            yoyo: false,
            repeat: 0,
            onComplete: () => {
                this.ressource.destroy();
                this.shadow.destroy();
            },
        });
        this.scene.tweens.add({
            targets: [this.ressource, this.shadow],
            scale: 0.2,
            alpha: 0.3,
            yoyo: false,
            duration: 500,
            ease: 'Sine.easeInOut'
        });

        if (isReward) {
            // register reward
            if (!this.scene.player.rewards[this.key]) {
                this.scene.player.rewards[this.key] = true;
                this.scene.player.dataManager.saveRewards(this.scene.player.rewards);
                
                // recalculate menu
                const craftingScene = this.scene.scene.get("CraftingScene");
                if (craftingScene.tier < this.scene.player.tier) {
                    craftingScene.initMenuSlots();
                    craftingScene.tier = this.scene.player.tier;
                }
                craftingScene.initStartItem();

            }
        } else {
            this.scene.player.inventory.addItem({ key: this.key, quantity: this.quantity });
        }
    }
}