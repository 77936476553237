import UIBaseScene from "./UIBaseScene.js";
import InventoryItem from "./InventoryItem";
import CraftingBar from "./CraftingBar";
import SpawnItem from "./SpawnItem.js";
import CardServant from "./CardServant";
import ButtonCrafting from "./ButtonCrafting";

export default class CraftingScene extends UIBaseScene {
    constructor() {
        super("CraftingScene");
        this.craftingSlots = [];
        this.uiScale = 1.0;
        this.showMenu = false;
        this.selectedItem = null;
        this.moveLeft = 50;
        this.frameTime = 0;
        this.tier = 1;
    }

    init(data) {
        let { mainscene } = data;

        this.mainscene = mainscene;
        this.player = mainscene.player;
        this.tier = this.player.tier;

        this.background = this.add.rectangle(0, 0, this.sys.game.canvas.width, this.sys.game.canvas.height, 0x000000, 0.7);
        this.background.setVisible(false);
        this.background.setOrigin(0, 0);
        this.background.setInteractive();
        this.background.on('pointerup', (pointer) => {
            this.showMenu = false;
            this.showMenuSlots();
            this.background.setVisible(false);
        });

        this.crafting = mainscene.crafting;
        this.crafting.inventory.subscribe(() => {
            this.showMenuSlots();
            this.recalculateMenuSlots();
        });

        /* if (this.player.cards.length === 0) {
            this.hand = this.add.sprite((this.sys.game.canvas.width / 2), this.sys.game.canvas.height - 20, 'hand');
            this.hand.setOrigin(0.5, 0.5);
            this.hand.setDepth(9);
            this.hand.anims.play("hand", false);
        } */

        this.craftingButton = new ButtonCrafting(this, (this.sys.game.canvas.width / 2) + this.tileSize + (this.margin * 2) + this.moveLeft, this.sys.game.canvas.height - this.margin)
        this.craftingButton.events.on('complete', () => {
            this.crafting.craft((item) => {
                new SpawnItem({
                    key: item.key,
                    scene: this,
                    mainscene: this.mainscene,
                    x: (this.sys.game.canvas.width / 2) - 50,
                    y: this.sys.game.canvas.height - 350,
                    isCard: item.isCard,
                    dirStart: 220,
                    dirStop: 310,
                    radius: 8,
                    showSmoke: false,
                    // gravity: 2.5,
                });
                this.recalculateMenuSlots();
            });
        });

        this.bag = this.add.image(this.sys.game.canvas.width, this.sys.game.canvas.height - this.margin, 'bag');
        this.bag.setScale(0.6);
        this.bag.setOrigin(1, 1);
        this.bag.setInteractive();

        this.rewards = this.add.image(this.sys.game.canvas.width, this.margin * 10, 'rewards');
        this.rewards.setScale(0.6);
        this.rewards.setOrigin(1, 1);
        this.rewards.setInteractive();
        this.rewards.setDepth(8);

        this.servantButton = this.add.image(this.margin * 9.5, this.margin * 10, 'slime-button');
        this.servantButton.setScale(0.6);
        this.servantButton.setOrigin(1, 1);
        this.servantButton.setInteractive();
        this.servantButton.setDepth(8);

        this.servant = new CardServant({ scene: this, x: this.sys.game.canvas.width / 2, y: this.sys.game.canvas.height / 2 });

        this.initMenuSlots();
        this.initStartItem();
    }

    create() {
        this.showMenuSlots();
        this.recalculateMenuSlots();
        this.bag.on('pointerup', (pointer) => {
            const inventoryScene = this.game.scene.getScene('InventoryScene');
            inventoryScene.open();
        });
        this.rewards.on('pointerup', (pointer) => {
            const rewardScene = this.game.scene.getScene('RewardScene');
            rewardScene.open();
        });
        this.servantButton.on('pointerup', (pointer) => {
            this.servant.toggleFlip();
        });
        window.addEventListener('resize', () => {
            this.rewards.setX(this.sys.game.canvas.width);
            this.bag.setX(this.sys.game.canvas.width);
            this.bag.setY(this.sys.game.canvas.height);
        });

        // this.events.on('update', this.update, this);
    }

    showMenuSlots() {
        for (let index = 0; index < this.crafting.items.length; index++) {
            this.craftingSlots[index].visible = this.showMenu;
            this.craftingSlots[index].item.visible = this.showMenu;
            for (let matIndex = 0; matIndex < this.craftingSlots[index].matItems.length; matIndex++) {
                this.craftingSlots[index].matItems[matIndex].visible = this.showMenu;
            }
        }
    }

    initStartItem() {
        this.crafting.calculateItems();
        const startItem = this.crafting.items.find(item => item.key === 'coal-' + this.mainscene.player.tier);
        this.crafting.selected = this.crafting.items.indexOf(startItem);

        this.selectedItem = new InventoryItem(this, (this.sys.game.canvas.width / 2) - (this.tileSize * 2) - (this.margin * 2) + this.moveLeft, this.sys.game.canvas.height - this.margin, startItem.key);
        this.selectedItem.setTint(startItem.canCraft ? 0xffffff : 0x555555);
        this.selectedItem.setQuantity(this.crafting.inventory.getItemQuantity(startItem.key));
        this.selectedItem.setRequired(startItem.canCraftQuantity);
        this.selectedItem.setRequiredColor(startItem.canCraftQuantity ? 'green' : 'red');
        this.selectedItem.events.on('click', () => {
            this.showMenu = !this.showMenu;
            this.background.setVisible(this.showMenu);
            this.showMenuSlots();
            if (this.hand) {
                this.hand.destroy();
            }
        });

        this.slot01item = new InventoryItem(this, (this.sys.game.canvas.width / 2) - this.tileSize - this.margin + this.moveLeft, this.sys.game.canvas.height - this.margin, startItem.matDetails[0].key);
        this.slot01item.setTint(startItem.matDetails[0].available ? 0xffffff : 0x555555);
        this.slot01item.setQuantity(this.crafting.inventory.getItemQuantity(startItem.matDetails[0].key));
        this.slot01item.setRequired(startItem.matDetails[0].quantity);
        this.slot01item.setRequiredColor(startItem.matDetails[0].available ? 'green' : 'red');
        this.slot01item.events.on('click', () => {
            this.showMenu = !this.showMenu;
            this.background.setVisible(this.showMenu);
            this.showMenuSlots();
            if (this.hand) {
                this.hand.destroy();
            }
        });

        this.slot02item = new InventoryItem(this, (this.sys.game.canvas.width / 2) + this.moveLeft, this.sys.game.canvas.height - this.margin, startItem.matDetails[1].key);
        this.slot02item.setTint(startItem.matDetails[1].available ? 0xffffff : 0x555555);
        this.slot02item.setQuantity(this.crafting.inventory.getItemQuantity(startItem.matDetails[1].key));
        this.slot02item.setRequired(startItem.matDetails[1].quantity);
        this.slot02item.setRequiredColor(startItem.matDetails[1].available ? 'green' : 'red');
        this.slot02item.events.on('click', () => {
            this.showMenu = !this.showMenu;
            this.background.setVisible(this.showMenu);
            this.showMenuSlots();
            if (this.hand) {
                this.hand.destroy();
            }
        });
    }

    initMenuSlots() {

        this.crafting.calculateItems();
        this.background.setVisible(false);

        for (let index = 0; index < this.crafting.items.length; index++) {

            const item = this.crafting.items[index];
            const x = (this.sys.game.canvas.width / 2) - (this.tileSize * 2) - (this.margin * 2) + this.moveLeft;
            const y = this.game.config.height - (index * (this.tileSize + this.margin)) - (this.tileSize + (this.margin * 2));

            this.craftingSlots[index] = new InventoryItem(this, x, y, item.key);
            this.craftingSlots[index].setTint(item.canCraft ? 0xffffff : 0x555555);
            this.craftingSlots[index].setQuantity(this.crafting.inventory.getItemQuantity(item.key));
            this.craftingSlots[index].setRequired(item.canCraftQuantity);
            this.craftingSlots[index].setRequiredColor(item.canCraftQuantity ? 'green' : 'red');
            this.craftingSlots[index].matItems = [];

            for (let matIndex = 0; matIndex < item.matDetails.length; matIndex++) {
                const matItem = item.matDetails[matIndex];
                const matItemSlot = new InventoryItem(this, x + this.tileSize + ((matIndex * this.tileSize) + (matIndex * this.margin)) + this.margin, y, matItem.key);
                matItemSlot.setTint(matItem.available ? 0xffffff : 0x555555);
                matItemSlot.setQuantity(this.crafting.inventory.getItemQuantity(matItem.key));
                matItemSlot.setRequired(matItem.quantity);
                matItemSlot.setRequiredColor(matItem.available ? 'green' : 'red');
                matItemSlot.events.on('click', (pointer) => {
                    this.closeMenu(index, item);
                });
                this.craftingSlots[index].matItems.push(matItemSlot);
            }

            this.craftingSlots[index].events.on('click', (pointer) => {
                this.closeMenu(index, item);
            });
        }
    }

    closeMenu(index, item) {
        this.stopCrafting();
        this.background.setVisible(false);

        this.selectedItem.destroy();
        this.selectedItem = new InventoryItem(this, (this.sys.game.canvas.width / 2) - (this.tileSize * 2) - (this.margin * 2) + this.moveLeft, this.sys.game.canvas.height - this.margin, item.key);
        this.selectedItem.setTint(item.canCraft ? 0xffffff : 0x555555);
        this.selectedItem.setQuantity(this.crafting.inventory.getItemQuantity(item.key));
        this.selectedItem.setRequired(item.canCraftQuantity);
        this.selectedItem.setRequiredColor(item.canCraftQuantity ? 'green' : 'red');
        this.selectedItem.events.on('click', () => {
            this.showMenu = !this.showMenu;
            this.background.setVisible(this.showMenu);
            this.showMenuSlots();
        });

        this.slot01item.destroy();
        this.slot01item = new InventoryItem(this, (this.sys.game.canvas.width / 2) - this.tileSize - this.margin + this.moveLeft, this.sys.game.canvas.height - this.margin, item.matDetails[0].key);
        this.slot01item.setQuantity(this.crafting.inventory.getItemQuantity(item.matDetails[0].key));
        this.slot01item.setRequired(item.matDetails[0].quantity);
        this.slot01item.setTint(item.matDetails[0].available ? 0xffffff : 0x555555);
        this.slot01item.events.on('click', () => {
            this.showMenu = !this.showMenu;
            this.background.setVisible(this.showMenu);
            this.showMenuSlots();
        });

        this.slot02item.destroy();
        this.slot02item = new InventoryItem(this, (this.sys.game.canvas.width / 2) + this.moveLeft, this.sys.game.canvas.height - this.margin, item.matDetails[1].key);
        this.slot02item.setQuantity(this.crafting.inventory.getItemQuantity(item.matDetails[1].key));
        this.slot02item.setRequired(item.matDetails[1].quantity);
        this.slot02item.setTint(item.matDetails[1].available ? 0xffffff : 0x555555);
        this.slot02item.events.on('click', () => {
            this.showMenu = !this.showMenu;
            this.background.setVisible(this.showMenu);
            this.showMenuSlots();
        });

        this.crafting.selected = index;
        this.showMenu = false;
        this.showMenuSlots();
        this.recalculateMenuSlots();
    }

    recalculateMenuSlots() {
        this.crafting.calculateItems();
        for (let index = 0; index < this.crafting.items.length; index++) {
            this.craftingSlots[index].setTint(this.crafting.items[index].canCraft ? 0xffffff : 0x555555);
            this.craftingSlots[index].setQuantity(this.crafting.inventory.getItemQuantity(this.crafting.items[index].key));
            this.craftingSlots[index].setRequired(this.crafting.items[index].canCraftQuantity);
            this.craftingSlots[index].setRequiredColor(this.crafting.items[index].canCraftQuantity ? 'green' : 'red');
            for (let matIndex = 0; matIndex < this.crafting.items[index].matDetails.length; matIndex++) {
                this.craftingSlots[index].matItems[matIndex].setTint(this.crafting.items[index].matDetails[matIndex].available ? 0xffffff : 0x555555);
                this.craftingSlots[index].matItems[matIndex].setQuantity(this.crafting.inventory.getItemQuantity(this.crafting.items[index].matDetails[matIndex].key));
            }
        }
        const currentItem = this.crafting.items[this.crafting.selected];
        this.selectedItem.setTint(currentItem.canCraft ? 0xffffff : 0x555555);
        this.selectedItem.setQuantity(this.crafting.inventory.getItemQuantity(currentItem.key));
        this.selectedItem.setRequired(currentItem.canCraftQuantity);
        this.selectedItem.setRequiredColor(currentItem.canCraftQuantity ? 'green' : 'red');
        this.slot01item.setTint(currentItem.matDetails[0].available ? 0xffffff : 0x555555);
        this.slot01item.setQuantity(this.crafting.inventory.getItemQuantity(currentItem.matDetails[0].key));
        this.slot02item.setTint(currentItem.matDetails[1].available ? 0xffffff : 0x555555);
        this.slot02item.setQuantity(this.crafting.inventory.getItemQuantity(currentItem.matDetails[1].key));

        this.craftingButton.canCraft = currentItem.canCraft;
        if (!currentItem.canCraft) {
            this.stopCrafting();
        }
    }

    stopCrafting() {
        this.craftingButton.setButtonActive(false);
    }
}
