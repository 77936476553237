import items from "./Items.js";

export default class Crafting {

    constructor(data) {
        let { mainscene } = data;
        this.mainscene = mainscene;
        this.inventory = mainscene.player.inventory;
        this.player = mainscene.player;
        this.selected = 0;
        this.items = [];
    }

    craft(successCallback) {
        const item = this.items[this.selected];
        if (item.canCraft) {
            item.matDetails.forEach(matDetail => this.inventory.removeItem(matDetail.key, matDetail.quantity));
            this.calculateItems();
            successCallback(item);
        }
    }

    calculateItems() {
        this.items = [];
        for (var key in items) {
            const item = items[key];
            if (item.mats) {
                const mats = item.mats;
                let lastMat = "";
                const matDetails = [];
                let canCraft = true;
                let canCraftQuantity = 0;
                let quantity = 0;
                mats.forEach(mat => {
                    const matKey = mat.key.replace('${tier}', this.mainscene.player.tier);
                    quantity = (lastMat.key === matKey) ? quantity - mat.quantity : this.inventory.getItemQuantity(matKey);
                    if (canCraftQuantity === 0 || canCraftQuantity > Math.floor(quantity / mat.quantity)) {
                        canCraftQuantity = Math.floor(quantity / mat.quantity);
                    }
                    let available = (quantity >= mat.quantity);
                    matDetails.push({ key: matKey, quantity: mat.quantity, available });
                    lastMat = mat;
                    if (!available) {
                        canCraft = false;
                    }
                });
                this.items.push({
                    order: item.order,
                    key: item.key.replace('${tier}', this.mainscene.player.tier),
                    matDetails,
                    canCraft,
                    canCraftQuantity: canCraftQuantity || 0,
                    isCard: items[key].isCard,
                });
                this.items.sort((a, b) => {
                    if (a.order > b.order) return -1;
                    if (a.order < b.order) return 1;
                    return 0;
                });
            }
        }
    }
}