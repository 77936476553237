export default class ButtonPlus extends Phaser.GameObjects.Container {

    public shaker: any;
    public dice: Phaser.GameObjects.Image;
    public events: Phaser.Events.EventEmitter = new Phaser.Events.EventEmitter();
    public isBusy: boolean = false;
    public hasNotEnoughtRessources: boolean = false;

    get isDisabled() {
        return this.isBusy || this.hasNotEnoughtRessources;
    }

    constructor(scene, x, y, key) {

        const dice = new Phaser.GameObjects.Image(scene, 0, 0, key);

        super(scene, x, y, [dice]);

        this.dice = dice;
        this.scene = scene;

        this.dice.setScale(0.36);
        this.setSize(this.dice.width * 0.36, this.dice.height * 0.36);
        this.setInteractive();

        this.draw();

        this.shaker = (this.scene.plugins.get('rexshakepositionplugin') as any).add(this, {
            duration: 200,
            magnitude: 10,
            mode: 'effect'
        }).on('complete', function () {

        });

        this.on('pointerup', (pointer) => {
            if (!this.isBusy) {
                this.shaker.shake();
                this.events.emit('complete');
            }
        });

        scene.add.existing(this);
    }

    setBusy(value: boolean) {
        this.isBusy = value;
        this.draw();
    }

    setNotEnoughRessources(value: boolean) {
        this.hasNotEnoughtRessources = value;
        this.draw();
    }

    draw() {
        this.dice.tint = this.isBusy || this.hasNotEnoughtRessources ? 0x555555 : 0xffffff;
    }
}