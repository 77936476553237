import IDropOptionData from "./data/IDropOptionData";
import MainScene from "./MainScene";

export default class BaseObject extends Phaser.GameObjects.Container {

    public scene: MainScene;
    private _dropOptions: IDropOptionData[] = [];

    get dropOptions(): IDropOptionData[] {
        return this._dropOptions;
    }
    set dropOptions(value: IDropOptionData[]) {
        this._dropOptions = value;
    }

    constructor(scene: MainScene, x: number, y: number, elements: any[]) {
        super(scene, x, y, elements);
        this.scene = scene;
    }

    getDropOption(defaultValue: string, dropOptions: IDropOptionData[]): string {
        const options = dropOptions.length ? dropOptions : this.dropOptions;
        if (options.length === 0) {
            return defaultValue.replace('${tier}', this.scene.player.tier);
        }
        if(this.scene.player.cheatsActivated) {
            let rarestChance = 100;
            let rarest = null;
            options.forEach((item) => {
                if (item.chance < rarestChance) {
                    rarest = item;
                    rarestChance = item.chance;
                }
            });
            if (rarest) {
                return rarest.key.replace('${tier}', this.scene.player.tier);
            }
        }
        return this.getRandomWeightedValue(options);
    }

    getRandomWeightedValue(options: IDropOptionData[]) {
        const totalSum = options.reduce((acc, item) => acc + item.chance, 0);
        let runningTotal = 0;
        const cumulativeValues = options.map((option) => {
            const relativeValue = option.chance / totalSum;
            const cv = {
                key: option.key.replace('${tier}', this.scene.player.tier),
                value: relativeValue + runningTotal
            };
            runningTotal += relativeValue;
            return cv;
        });
        return cumulativeValues.find(({ key, value }) => Math.random() <= value)!.key;
    }
}