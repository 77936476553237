export default class UIBaseScene extends Phaser.Scene {
    constructor(key){
        super(key);
        this.margin = 16;
        this.uiScale = 1.0;
        this._tileSize = 128;
    }

    get tileSize() {
        return this._tileSize * this.uiScale;
    }

}