import SpawnItem from "./SpawnItem.js";
import RessourceBar from "./RessourceBar.js";
import BaseObject from "./BaseObject";

export default class LootableResource extends BaseObject {

    constructor(data) {
        let { scene, x, y, title, key, dropOptions, limit, limitTool, deck } = data;

        const width = 264 + 20;
        const height = 375 + 20;

        const textOptions = {
            font: "45px Arial",
            fill: "#fff"
        };
        const textLimitOptions = {
            font: "35px Arial",
            fill: "#fff"
        };

        const ressource = new Phaser.GameObjects.Graphics(scene);
        const textName = new Phaser.GameObjects.Text(scene, 0, 0, title, textOptions);
        const limitText = new Phaser.GameObjects.Text(scene, 0, -(height / 2) - 40, '0 / ' + limit, textLimitOptions);
        const progress = new RessourceBar({ scene, x: 0, y: -(width / 2) + 30, value: 0, maxValue: 100, width: 200 });
        
        super(scene, x, y, [ressource, textName, limitText, progress]);
        
        this.ressource = ressource;
        this.limitText = limitText;
        this.scene = scene;
        this.x = x;
        this.y = y;
        this.progress = progress;
        this.title = title;
        this.key = key;
        this.dropOptions = dropOptions;
        this.limit = limit;
        this.limitTool = limitTool;

        this.deck = deck;
        this.frameTime = 0;
        this.frameTimePlayer = 0;
        this.frameTimeEnemy = 0;
        this.framePointerDown = 0;

        this.isPointerDown = false;
        this.isPointerDownDelay = false;

        this.cardWidth = width;
        this.cardHeight = height;

        this.deck.limit = limit;

        textName.x = -textName.width / 2;
        textName.y = -10;
        this.limitText.setOrigin(0.5, 0.5);

        this.draw();

        this.setSize(this.cardWidth, this.cardHeight + 100);
        this.setInteractive({ dropZone: true });
        this.scene.add.existing(this);

        this.progress.onComplete.on('complete', () => {
            new SpawnItem({
                key: this.getDropOption(this.key, []),
                scene: this.scene,
                mainscene: this.scene,
                x: this.x - (this.width / 1.5),
                y: this.y - (this.height / 2),
                dirStart: 0,
                dirStop: 360,
            });
        });

        this.on('pointerdown', (pointer) => {
            this.isPointerDown = true;
            this.scene.time.delayedCall(1000, () => {
                this.isPointerDownDelay = true;
            });
        });

        this.on('pointerup', (pointer) => {
            this.isPointerDown = false;
            this.isPointerDownDelay = false;
            this.progress.addToValue(25);
            this.shaker.shake();
            if (this.scene.hand) {
                this.scene.hand.setVisible(false);
            }
        });

        this.shaker = this.scene.plugins.get('rexshakepositionplugin').add(this.ressource, {
            duration: 200,
            magnitude: 5,
            mode: 'effect'
        }).on('complete', function () {
        });

        scene.events.on('update', this.update, this);
    }

    cardAdded() {
        this.limitText.setText(this.deck.length + ' / ' + this.limit);
    }

    cardRemoved() {
        this.limitText.setText(this.deck.length + ' / ' + this.limit);
    }

    draw() {
        this.ressource.clear();
        this.ressource.lineStyle(4, 0xffffff, 1);
        this.ressource.strokeRoundedRect(-(this.cardWidth/2), -(this.cardHeight/2), this.cardWidth, this.cardHeight, 24);
    }

    update(time, delta) {

        if (this.isPointerDown && this.isPointerDownDelay) {
            this.framePointerDown += delta;
            const every = 250;
            while (this.framePointerDown > every) {
                this.framePointerDown -= every;
                this.progress.addToValue(25);
                this.shaker.shake();
            }
        }

        if (this.deck.cards.length > 0) {
            if (!this.deck.isEnemyDeck) {
                this.frameTime += delta;
                let speed = 0;
                this.deck.cards.forEach(card => {
                    speed += card.speed;
                });
                
                const every = (this.scene.player.tier * 1000) / speed;
                while (this.frameTime > every) {
                    this.frameTime -= every;
                    if (this.deck.length > 0) {
                        let increase = 0;
                        this.deck.cards.forEach(card => {
                            increase += card.strength;
                        });
                        this.progress.addToValue(increase || 1);
                    }
                }
            }

            // fight
            if(this.deck.isEnemyDeck) {

                const enemy = this.deck.cards[this.deck.cards.length - 2];
                const player = this.deck.getPlayer();

                if (enemy && player && enemy.constructor.name === 'CardEnemy' && player.constructor.name === 'Card') {
                    
                    const initialTime = 5000;

                    this.frameTimeEnemy += delta;
                    const playerTime = initialTime - (player.speed * 1000);
                    while (this.frameTimeEnemy > playerTime) {
                        this.frameTimeEnemy -= playerTime;
                        if (enemy.isHitting) {
                            this.scene.time.delayedCall(300, () => {
                                player.hit(enemy, this.deck);
                            });
                        } else {
                            player.hit(enemy, this.deck);
                        }
                    }

                    this.frameTimePlayer += delta;
                    const enemyTime = initialTime - (enemy.speed * 1000);
                    while (this.frameTimePlayer > enemyTime) {
                        this.frameTimePlayer -= enemyTime;
                        if (enemy.isHitting) {
                            this.scene.time.delayedCall(300, () => {
                                enemy.hit(player, this.deck);
                            });
                        } else {
                            enemy.hit(player, this.deck);
                        }
                    }
                }
            }
        }
    }
}