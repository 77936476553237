export default class Button extends Phaser.GameObjects.Container {
    constructor(data) {
        let { scene, x, y, width, height, text } = data;

        const textOptions = {
            font: "bold 40px Arial",
            fill: "#111"
        };

        const border = new Phaser.GameObjects.Graphics(scene);
        const background = new Phaser.GameObjects.Rectangle(scene, 0, 0, width, height, 0xffffff, 0.8);
        const buttonText = new Phaser.GameObjects.Text(scene, 0, 0, text, textOptions);

        super(scene, x, y, [background, border, buttonText]);

        this.background = background;
        this.border = border;
        this.scene = scene;
        this.width = width;
        this.height = height;
        this.x = x;
        this.y = y;
        this.buttonText = buttonText;
        this.events = new Phaser.Events.EventEmitter();

        this.setSize(width, height);
        this.setInteractive();
        this.buttonText.setOrigin(0.5, 0.5),

        this.on('pointerup', (pointer) => {
            this.events.emit('click');
            this.background.setAlpha(0.2);
            this.scene.time.delayedCall(100, () => {
                this.background.setAlpha(0.8);
            });
        });

        this.draw();

        scene.add.existing(this);
    }

    draw() {
        this.border.lineStyle(5, 0xffffff, 1);
        this.border.strokeRoundedRect(-(this.width / 2), -(this.height / 2), this.width, this.height, 5);
    }
}