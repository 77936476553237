import MainScene from "./MainScene";

export default class InventoryItem extends Phaser.GameObjects.Container {

    public scene: MainScene;
    public background: Phaser.GameObjects.Rectangle;
    public border: Phaser.GameObjects.Graphics;
    public item: Phaser.GameObjects.Image;
    public requiredText: Phaser.GameObjects.Text;
    public quantityText: Phaser.GameObjects.Text;
    public events: Phaser.Events.EventEmitter = new Phaser.Events.EventEmitter();
    public key: string;
    public textOptions: Phaser.Types.GameObjects.Text.TextStyle = {
        font: "bold 35px Arial",
        fill: "#111",
    } as Phaser.Types.GameObjects.Text.TextStyle;
    public textOptionsGreen: Phaser.Types.GameObjects.Text.TextStyle = {
        font: "bold 35px Arial",
        fill: "#228B22",
    } as Phaser.Types.GameObjects.Text.TextStyle;
    public textOptionsRed: Phaser.Types.GameObjects.Text.TextStyle = {
        font: "bold 35px Arial",
        fill: "#FF0000",
    } as Phaser.Types.GameObjects.Text.TextStyle;

    constructor(scene: MainScene, x: number, y: number, key: string, quantity?: number) {

        const cellSize: number = 128;
        const item = new Phaser.GameObjects.Image(scene, -(cellSize / 2), -(cellSize / 2), key);
        const border = new Phaser.GameObjects.Graphics(scene);
        const background = new Phaser.GameObjects.Rectangle(scene, -(cellSize / 2), -(cellSize / 2), cellSize, cellSize, 0xffffff, 0.7);
        const requiredText = new Phaser.GameObjects.Text(scene, 0, -(cellSize), '', {
            font: "bold 35px Arial",
            fill: "#111",
            stroke: '#FFFFFF',
            strokeThickness: 6,
        } as Phaser.Types.GameObjects.Text.TextStyle);
        const quantityText = new Phaser.GameObjects.Text(scene, 0, -(cellSize / 3), '', {
            font: "bold 30px Arial",
            fill: "#111",
            stroke: '#FFFFFF',
            strokeThickness: 6,
        } as Phaser.Types.GameObjects.Text.TextStyle);

        super(scene, x, y, [background, border, item, requiredText, quantityText]);

        this.scene = scene;
        this.item = item;
        this.background = background;
        this.border = border;
        this.requiredText = requiredText;
        this.quantityText = quantityText;
        this.key = key;

        this.item.setScale(0.5);
        this.setSize(cellSize, cellSize);
        this.background.setInteractive();
        this.requiredText.setOrigin(1, 0);
        this.quantityText.setOrigin(1, 0);

        if (quantity !== undefined) {
            this.setQuantity(quantity);
        }
        this.item.setVisible(this.key ? true : false);

        this.border.lineStyle(10, 0xffffff, 1);
        this.border.strokeRoundedRect(-(cellSize), -(cellSize), cellSize, cellSize, 5);

        this.background.on('pointerup', (pointer) => {
            this.events.emit('click', pointer);
        });

        scene.add.existing(this);
    }

    setTint(value: number) {
        this.item.setTint(value);
    }

    setRequired(value: number) {
        if (this.key) {
            this.requiredText.setText(value.toFixed(0));
        }
    }

    setQuantity(value: number) {
        if (this.key) {
            this.quantityText.setText(value.toFixed(0));
        }
    }

    setRequiredColor(value: string) {
        this.requiredText.setStyle(value === 'red' ? this.textOptionsRed : (value === 'green' ? this.textOptionsGreen : this.textOptions));
    }
}