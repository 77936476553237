let items = {
    axe: {
        isCard: true,
        key: 'axe-${tier}',
        mats: [{ key: 'wood-${tier}', quantity: 2 }, { key: 'metal-${tier}', quantity: 2 }],
        order: 2,
    },
    pickaxe: {
        isCard: true,
        key: 'pickaxe-${tier}',
        mats: [{ key: 'wood-${tier}', quantity: 2 }, { key: 'metal-${tier}', quantity: 2 }],
        order: 3,
    },
    sword: {
        isCard: true,
        key: 'sword-${tier}',
        mats: [{ key: 'branch-${tier}', quantity: 1 }, { key: 'metal-${tier}', quantity: 4 }],
        order: 1,
    },
    coal: {
        key: 'coal-${tier}',
        mats: [{ key: 'wood-${tier}', quantity: 3 }, { key: 'wood-${tier}', quantity: 3 }],
        canServantUse: true,
        order: 5,
    },
    metal: {
        key: 'metal-${tier}',
        mats: [{ key: 'coal-${tier}', quantity: 1 }, { key: 'stone-${tier}', quantity: 2 }],
        canServantUse: true,
        order: 6,
    },
    shield: {
        key: 'shield-${tier}',
        mats: [{ key: 'branch-${tier}', quantity: 1 }, { key: 'bone-${tier}', quantity: 2 }, { key: 'metal-${tier}', quantity: 10 }],
        order: 4,
    },
    wood: {
        key: 'wood-${tier}'
    },
    stone: {
        key: 'stone-${tier}'
    },
};

export default items;
