let enemies = {
    level1: {
        speed: 0.75,
        strength: 1.20,
        maxHealth: 300,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-95', chance: 1, },
        ],
    },
    level2: {
        speed: 0.75,
        strength: 1.20,
        maxHealth: 400,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-98', chance: 1, },
        ],
    },
    level3: {
        speed: 0.75,
        strength: 1.20,
        maxHealth: 500,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-92', chance: 1, },
        ],
    },
    level4: {
        speed: 0.75,
        strength: 1.20,
        maxHealth: 600,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-104', chance: 1, },
        ],
    },
    level5: {
        speed: 1.00,
        strength: 2.40,
        maxHealth: 500,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-1', chance: 1, },
        ],
    },
    level6: {
        speed: 1.00,
        strength: 2.40,
        maxHealth: 600,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-37', chance: 1, },
        ],
    },
    level7: {
        speed: 1.00,
        strength: 2.40,
        maxHealth: 800,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-24', chance: 1, },
        ],
    },
    level8: {
        speed: 1.00,
        strength: 2.40,
        maxHealth: 900,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-110', chance: 1, },
        ],
    },
    level9: {
        speed: 1.25,
        strength: 3.60,
        maxHealth: 700,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-96', chance: 1, },
        ],
    },
    level10: {
        speed: 1.25,
        strength: 3.60,
        maxHealth: 800,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-99', chance: 1, },
        ],
    },
    level11: {
        speed: 1.25,
        strength: 3.60,
        maxHealth: 1000,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-93', chance: 1, },
        ],
    },
    level12: {
        speed: 1.25,
        strength: 3.60,
        maxHealth: 1200,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-105', chance: 1, },
        ],
    },
    level13: {
        speed: 1.50,
        strength: 4.80,
        maxHealth: 800,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-2', chance: 1, },
        ],
    },
    level14: {
        speed: 1.50,
        strength: 4.80,
        maxHealth: 1000,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-38', chance: 1, },
        ],
    },
    level15: {
        speed: 1.50,
        strength: 4.80,
        maxHealth: 1200,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-25', chance: 1, },
        ],
    },
    level16: {
        speed: 1.50,
        strength: 4.80,
        maxHealth: 1400,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-111', chance: 1, },
        ],
    },
    level17: {
        speed: 1.75,
        strength: 6.00,
        maxHealth: 1200,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-97', chance: 1, },
        ],
    },
    level18: {
        speed: 1.75,
        strength: 6.00,
        maxHealth: 1500,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-100', chance: 1, },
        ],
    },
    level19: {
        speed: 1.75,
        strength: 6.00,
        maxHealth: 1800,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-94', chance: 1, },
        ],
    },
    level20: {
        speed: 1.75,
        strength: 6.00,
        maxHealth: 2000,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-106', chance: 1, },
        ],
    },
    level21: {
        speed: 2.00,
        strength: 7.20,
        maxHealth: 1500,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-3', chance: 1, },
        ],
    },
    level22: {
        speed: 2.00,
        strength: 7.20,
        maxHealth: 2000,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-39', chance: 1, },
        ],
    },
    level23: {
        speed: 2.00,
        strength: 7.20,
        maxHealth: 2500,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-26', chance: 1, },
        ],
    },
    level24: {
        speed: 2.00,
        strength: 7.20,
        maxHealth: 3000,
        dropOptions: [
            { key: 'plus-${tier}', chance: 100 },
            { key: 'reward-112', chance: 1, },
        ],
    },
};

export default enemies;
