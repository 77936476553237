import Button from './Button';
import CardBase from './CardBase';
import InventoryItem from './InventoryItem';
import SpawnItem from './SpawnItem';
import items from "./Items.js";

export default class CardOffline extends CardBase {
    constructor(data) {

        const textOptions = {
            font: "30px Arial",
            fill: "#111"
        };
        const textTitleOptions = {
            font: "bold 35px Arial",
            fill: "#111"
        };

        let { scene, x, y } = data;
        const titleText = new Phaser.GameObjects.Text(scene, 0, 0, 'While you were offline', textTitleOptions);
        const button = new Button({ scene, x: 0, y: 300, width: 300, height: 100, text: 'OK' });

        super(scene, x, y, [titleText, button]);

        this.titleText = titleText;
        this.scene = scene;
        this.button = button;

        this.titleText.setPosition(0, -(this.height));
        this.card.setOrigin(0.5, 0.5);
        this.titleText.setOrigin(0.5, 0.5);
        this.setVisible(false);
        this.healthBar.setVisible(false);
        this.setDepth(9);
        this.items = [];
        this.itemsShown = [];

        this.button.events.on('click', () => {
            this.setVisible(false);
            this.isFlipped = false;
            this.items.forEach((item, index) => {
                new SpawnItem({
                    key: item.key,
                    scene: this.scene,
                    mainscene: this.scene,
                    x: -200,
                    y: -200,
                    dirStart: 0,
                    dirStop: 360,
                    quantity: item.quantity,
                });
            });
            this.items = [];
            this.itemsShown.forEach(item => item.destroy());
        });

        this.scene.add.existing(this);
    }

    toggleFlip(awayTime) {
        console.log('awayTime', awayTime);
                          
        let minutes = awayTime / 1000 / 60;
        const maxMinutes = this.scene.player.isServantActive ? 360 : 120;

        if (minutes > maxMinutes) {
            minutes = maxMinutes;
        }

        Object.values(this.scene.decksMap).forEach(deck => {
            if (!deck.ressource.deck.isEnemyDeck) {
                let speed = 0;
                let strength = 0;
                deck.ressource.deck.cards.forEach(card => {
                    strength += card.strength;
                    speed += card.speed;
                });
                if (strength && speed) {
                    const every = 1000 / speed;
                    const perMinute = (minutes * (60 * ((1000 / every) * strength)));
                    const quantity = perMinute / 100;
                    const results = {};
                    for (let i = 0; i < quantity; i++) {
                        const key = this.getDropOption(deck.key, deck.dropOptions);
                        if (!results[key]) {
                            results[key] = 0;
                        }
                        results[key]++;
                    }
                    Object.keys(results).forEach(key => {
                        this.items.push({ key, quantity: parseInt(Math.floor(results[key])) });
                    });
                }
            }
        });

        if (this.scene.player.isServantActive) {
            for (var key in items) {
                const item = items[key];
                if (item.canServantUse) {
                    const mats = {};
                    item.mats.forEach(mat => {
                        const matKey = mat.key.replace('${tier}', this.scene.player.tier);
                        if (!mats[matKey]) {
                            mats[matKey] = 0;
                        }
                        mats[matKey]++;
                    });
                    let itemsToCraft = 0;
                    Object.keys(mats).forEach(matKey => {
                        const item = this.items.find(item => item.key === matKey);
                        if (item) {
                            itemsToCraft = parseInt(Math.floor(item.quantity / mats[matKey]));
                            item.quantity = item.quantity % mats[matKey];
                        }
                    });
                    this.items.push({ key: item.key.replace('${tier}', this.scene.player.tier), quantity: itemsToCraft });
                }
            }
            this.items = this.items.filter(item => item.quantity > 0);
        }

        this.setVisible(!this.isFlipped);
        this.scene.tweens.add({
            targets: this.card,
            scaleX: this.cardScale * 0.01,
            ease: 'Linear',
            duration: 150,
            onComplete: () => {
                this.titleText.setVisible(!this.isFlipped);
                this.background.setVisible(!this.isFlipped);
                this.scene.tweens.add({
                    targets: this.card,
                    scaleX: this.isFlipped ? this.cardScale : this.cardScale * 2.5,
                    scaleY: this.isFlipped ? this.cardScale : this.cardScale * 2.5,
                    ease: 'Linear',
                    duration: 150,
                    onComplete: () => {
                        this.isFlipped = !this.isFlipped;
                        if (this.isFlipped) {
                            const maxColumns = 4;
                            const tileSize = 128;
                            const margin = 16;
                            for (let i = 0; i < this.items.length; i++) {
                                const item = this.items[i];
                                const x = ((i % maxColumns) * (tileSize + margin));
                                const y = (Math.floor(i / maxColumns) * (tileSize + margin));
                                const inventoryItem = new InventoryItem(this.scene, -150 + x, -100 + y, item.key, item.quantity);
                                this.itemsShown.push(inventoryItem)
                                this.add(inventoryItem)
                            }
                        }
                    },
                })
            },
        });
    }
}
