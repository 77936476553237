import UIBaseScene from "./UIBaseScene.js";
import InventoryItem from "./InventoryItem";
import rewards from "./Rewards.js";

export default class RewardScene extends UIBaseScene {
    constructor() {
        super("RewardScene");
        this.rows = 0;
        this.gridSpacing = 4;
        this.inventorySlots = [];
    }
    init(data) {
        let { mainScene } = data;
        this.mainScene = mainScene;
        this.inventory = mainScene.player.inventory;
        this.maxColumns = this.inventory.maxColumns;
        this.maxRows = this.inventory.maxRows;
        this.inventory.subscribe(() => this.refresh());
        this.background = new Phaser.GameObjects.Rectangle(this, 0, 0, this.sys.game.canvas.width, this.sys.game.canvas.height, 0x111111, 0.8);
        this.background.setOrigin(0, 0);
        this.background.setVisible(false);
        this.background.setInteractive();
        this.background.setDepth(9);
        this.background.on('pointerup', (pointer) => {
            this.close();
        })
        this.add.existing(this.background);
    }

    destroyInventorySlot(inventorySlot) {
        if (inventorySlot.item) inventorySlot.item.destroy();
        if (inventorySlot.quantityText) inventorySlot.quantityText.destroy();
        inventorySlot.destroy();
    }

    refresh() {
        this.inventorySlots.forEach((s) => this.destroyInventorySlot(s));
        this.inventorySlots = [];
        for (let index = 0; index < this.maxColumns * this.rows; index++) {
            const x = this.margin + this.tileSize + ((index % this.maxColumns) * (this.tileSize + this.margin));
            const y = this.margin + this.tileSize + (Math.floor(index / this.maxColumns) * (this.tileSize + (this.margin * 4)));
            const item = rewards[index];
            const inventorySlot = new InventoryItem(this, x, y, item ? item.key : '');
            inventorySlot.quantityText = this.add.text(inventorySlot.x - (inventorySlot.width / 2), inventorySlot.y + 10, item ? item.title : '', {
                font: "25px Arial",
                fill: "#ffffff"
            });
            inventorySlot.quantityText.setOrigin(0.5, 0);
            inventorySlot.quantityText.setDepth(9);
            inventorySlot.setDepth(9);
            inventorySlot.events.on('click', () => {
                this.close();
            });
            this.inventorySlots.push(inventorySlot);
        }
        this.updateSelected();
    }


    updateSelected() {
        for (let index = 0; index < this.maxColumns * this.rows; index++) {
            if (this.inventorySlots[index] && this.inventorySlots[index].item) {
                this.inventorySlots[index].setTint(this.mainScene.player.rewards[this.inventorySlots[index].key] ? 0xffffff : 0x111111);
            }
        }
    }

    create() {
        this.refresh();
    }

    open() {
        this.rows = this.rows === 0 ? this.maxRows : 0;
        this.background.setVisible(this.rows > 0);
        this.refresh();
    }

    close() {
        this.rows = 0;
        this.background.setVisible(false);
        this.refresh();
    }
}