

export default class DataManager {
    constructor() {
        this.items = JSON.parse(localStorage.getItem('items')) || {};
        this.cards = JSON.parse(localStorage.getItem('cards')) || [];
        this.decks = JSON.parse(localStorage.getItem('decks')) || [];
        this.rewards = JSON.parse(localStorage.getItem('rewards')) || {};
        this.cheats = localStorage.getItem('cheats') === '1' || false;
        this.servantTime = localStorage.getItem('servantTime') ? new Date(parseInt(localStorage.getItem('servantTime'))) : new Date();
    }

    saveItems(items) {
        localStorage.setItem('items', JSON.stringify(items));
    }

    saveCards(cards) {
        localStorage.setItem('cards', JSON.stringify(cards));
    }

    saveRewards(rewards) {
        localStorage.setItem('rewards', JSON.stringify(rewards));
    }

    saveServant(time) {
        this.servantTime = time;
        localStorage.setItem('servantTime', time.getTime());
    }

    saveDeck(name, cards) {
        let found = false;
        this.decks.forEach((deck) => {
            if (deck.name === name) {
                deck.cards = cards.map((card) => {
                    return { name: card.name };
                });
                found = true;
            }
        });

        if (!found) {
            this.decks.push({
                name: name,
                cards: cards.map((card) => {
                    return { name: card.name };
                }),
            });
        }

        localStorage.setItem('decks', JSON.stringify(this.decks));
    }
}