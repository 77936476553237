import CardBase from "./CardBase";
import DeckBase from "./DeckBase";
import MainScene from "./MainScene";

export default class Deck extends DeckBase {
    constructor(scene: MainScene, name: string) {
        super(scene, name);
    }

    addCard(card: CardBase, key: string, limitTool: string) {
        if (!this.cards.includes(card) && this.cards.length < this.limit && key.startsWith(limitTool)) {
            this.cards.push(card);
            this.scene.player.dataManager.saveDeck(this.name, this.cards);
            card.cardAddedToDeck(this);
            return true;
        }
        return false;
    }

    cardIndex(card: CardBase) {
        return this.cards.indexOf(card);
    }

    removeCard(card: CardBase) {
        this.cards = this.cards.filter((item) => {
            return item.name !== card.name;
        });
        card.cardRemovedFromDeck(this);
        this.scene.player.dataManager.saveDeck(this.name, this.cards);
    }
}