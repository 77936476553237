import CraftingScene from "./CraftingScene";

export default class ButtonCrafting extends Phaser.GameObjects.Container {

    public shaker: any;
    public buttonMask: Phaser.GameObjects.Graphics;
    public button: Phaser.GameObjects.Image;
    public buttonShadow: Phaser.GameObjects.Image;
    public craftingAnimation: Phaser.GameObjects.Sprite;
    public events: Phaser.Events.EventEmitter = new Phaser.Events.EventEmitter();
    public isActive: boolean = false;
    public scene: CraftingScene;
    public tween: Phaser.Tweens.Tween;
    private _canCraft: boolean = false;
    private buttonScale = 0.6;
    private buttonSize = 256;

    constructor(scene: CraftingScene, x: number, y: number) {

        const button = new Phaser.GameObjects.Image(scene, 0, 0, 'craft');
        const buttonShadow = new Phaser.GameObjects.Image(scene, 0, 0, 'craft');
        const craftingAnimation = scene.add.sprite(-80, -170, 'fire-30');
        const buttonMask = scene.add.graphics();
        
        super(scene, x, y, [craftingAnimation, buttonShadow, button]);

        this.button = button;
        this.buttonShadow = buttonShadow;
        this.buttonMask = buttonMask;
        this.craftingAnimation = craftingAnimation;
        this.scene = scene;
        this.x = x;
        this.y = y;

        this.buttonMask.x = x - (this.buttonSize * this.buttonScale);
        this.button.mask = new Phaser.Display.Masks.BitmapMask(scene, this.buttonMask);

        this.button.setScale(this.buttonScale);
        this.button.setOrigin(1, 1);
        this.buttonShadow.setScale(this.buttonScale);
        this.buttonShadow.setOrigin(1, 1);
        this.buttonShadow.tint = 0x555555;
        this.buttonMask.setVisible(false);
        this.setSize(this.button.width, this.button.height);
        this.setInteractive();

        this.craftingAnimation.setScale(1.5);
        this.craftingAnimation.setVisible(false);

        this.draw();

        this.shaker = (this.scene.plugins.get('rexshakepositionplugin') as any).add(this, {
            duration: 200,
            magnitude: 10,
            mode: 'effect'
        });

        this.on('pointerup', (pointer) => {
            if (this.canCraft) {
                this.isActive = !this.isActive;
                if (this.isActive) {
                    this.shaker.shake();
                    this.craftingAnimation.anims.play('fire-30', true);
                    this.buttonMask.y = this.y - 35;
                    this.tween = this.scene.tweens.add({
                        targets: this.buttonMask,
                        y: this.y - (this.buttonSize * this.buttonScale) - 10,
                        ease: 'Linear',
                        repeat: 0,
                        loop: -1,
                        duration: this.craftingTime,
                        onLoop: () => {
                            this.events.emit('complete');
                        },
                    });
                } else {
                    this.craftingAnimation.anims.stop();
                    this.tween.stop();
                    this.calculateButton();
                }
                this.craftingAnimation.setVisible(this.isActive);
            }
        });

        this.scene.add.existing(this);
    }

    get craftingTime(): number {
        return 4000 - (4000 * (this.scene.player.getRewardAttribute('crafting') / 100));
    }

    get canCraft(): boolean {
        return this._canCraft;
    }
    set canCraft(value: boolean) {
        const oldValue = this._canCraft;
        this._canCraft = value;
        if (value !== oldValue) {
            this.calculateButton();
        }
    }
    
    draw() {
        this.buttonMask.clear();
        this.buttonMask.fillStyle(0xffffff, 1);
        this.buttonMask.fillRect(0, 25, 256 * this.buttonScale, 256 * this.buttonScale - 25);
    }

    setButtonActive(value: boolean): void {
        if (this.isActive !== value) {
            this.craftingAnimation.setVisible(value);
            this.isActive = value;
            if (this.isActive) {
                this.buttonMask.y = this.y - (this.buttonSize * this.buttonScale) - 10;
            } else {
                if (this.tween) {
                    this.tween.stop();
                }
                this.buttonMask.y = this.y - 10;
            }
            this.button.setVisible(!this.isActive);
            this.calculateButton();
        }
    }

    calculateButton() {
        if (this._canCraft) {
            this.buttonMask.y = this.y - (this.buttonSize * this.buttonScale) - 10;
        } else {
            this.buttonMask.y = this.y - 10;
        }
    }
}