let rewards = [{
        tier: 1,
        bossLevel: 1,
        title: 'Luck 2%',
        key: 'reward-95',
        attributes: [
            { name: 'luck', value: 2 },
        ],
    }, {
        tier: 3,
        bossLevel: 9,
        title: 'Luck 5%',
        key: 'reward-96',
        attributes: [
            { name: 'luck', value: 5 },
        ],
    }, {
        tier: 5,
        bossLevel: 17,
        title: 'Luck 8%',
        key: 'reward-97',
        attributes: [
            { name: 'luck', value: 8 },
        ],
    }, {
        tier: 1,
        bossLevel: 2,
        title: 'Str 5%',
        key: 'reward-98',
        attributes: [
            { name: 'strength', value: 5 },
        ],
    }, {
        tier: 3,
        bossLevel: 10,
        title: 'Str 10%',
        key: 'reward-99',
        attributes: [
            { name: 'strength', value: 10 },
        ],
    }, {
        tier: 5,
        bossLevel: 18,
        title: 'Str 15%',
        key: 'reward-100',
        attributes: [
            { name: 'strength', value: 15 },
        ],
    }, {
        tier: 1,
        bossLevel: 3,
        title: 'Speed 10%',
        key: 'reward-92',
        attributes: [
            { name: 'speed', value: 10 },
        ],
    }, {
        tier: 3,
        bossLevel: 11,
        title: 'Speed 15%',
        key: 'reward-93',
        attributes: [
            { name: 'speed', value: 15 },
        ],
    }, {
        tier: 5,
        bossLevel: 19,
        title: 'Speed 20%',
        key: 'reward-94',
        attributes: [
            { name: 'speed', value: 20 },
        ],
    }, {
        tier: 1,
        bossLevel: 4,
        title: 'Health 10%',
        key: 'reward-104',
        attributes: [
            { name: 'health', value: 10 },
        ],
    }, {
        tier: 3,
        bossLevel: 12,
        title: 'Health 15%',
        key: 'reward-105',
        attributes: [
            { name: 'health', value: 15 },
        ],
    }, {
        tier: 5,
        bossLevel: 20,
        title: 'Health 20%',
        key: 'reward-106',
        attributes: [
            { name: 'health', value: 20 },
        ],
    }, {
        tier: 2,
        bossLevel: 5,
        title: 'Crit 3%',
        key: 'reward-1',
        attributes: [
            { name: 'critical', value: 3 },
        ],
    }, {
        tier: 4,
        bossLevel: 13,
        title: 'Crit 5%',
        key: 'reward-2',
        attributes: [
            { name: 'critical', value: 5 },
        ],
    }, {
        tier: 5,
        bossLevel: 21,
        title: 'Crit 7%',
        key: 'reward-3',
        attributes: [
            { name: 'critical', value: 7 },
        ],
    }, {
        tier: 2,
        bossLevel: 6,
        title: 'Craft 10%',
        key: 'reward-37',
        attributes: [
            { name: 'crafting', value: 10 },
        ],
    }, {
        tier: 4,
        bossLevel: 14,
        title: 'Craft 20%',
        key: 'reward-38',
        attributes: [
            { name: 'crafting', value: 20 },
        ],
    }, {
        tier: 5,
        bossLevel: 22,
        title: 'Craft 30%',
        key: 'reward-39',
        attributes: [
            { name: 'crafting', value: 30 },
        ],
    }, {
        tier: 2,
        bossLevel: 7,
        title: '2x drop 5%',
        key: 'reward-24',
        attributes: [
            { name: '2xdropchnace', value: 5 },
        ],
    }, {
        tier: 4,
        bossLevel: 15,
        title: '2x drop 10%',
        key: 'reward-25',
        attributes: [
            { name: '2xdropchnace', value: 10 },
        ],
    }, {
        tier: 6,
        bossLevel: 23,
        title: '2x drop 15%',
        key: 'reward-26',
        attributes: [
            { name: '2xdropchnace', value: 15 },
        ],
    }, {
        tier: 2,
        bossLevel: 8,
        title: 'Offline 10%',
        key: 'reward-110',
        attributes: [
            { name: 'offline', value: 10 },
        ],
    }, {
        tier: 4,
        bossLevel: 16,
        title: 'Offline 20%',
        key: 'reward-111',
        attributes: [
            { name: 'offline', value: 20 },
        ],
    }, {
        tier: 6,
        bossLevel: 24,
        title: 'Offline 30%',
        key: 'reward-112',
        attributes: [
            { name: 'offline', value: 30 },
        ],
    },
    // destruction risk
];

export default rewards;
