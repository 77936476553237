import Inventory from "./Inventory.js";
import DataManager from "./DataManager.js";
import Card from "./Card.js";
import rewards from "./Rewards.js";

export default class Player {

    public dataManager: DataManager = new DataManager();
    public inventory: Inventory;
    public cards: Array<Card> = [];
    public rewards: { [key: string]: boolean } = {};

    constructor() {

        this.inventory = new Inventory();
        this.inventory.items = this.dataManager.items;
        // this.cards = this.dataManager.cards;
        this.rewards = this.dataManager.rewards;

        this.inventory.subscribe(() => {
            this.dataManager.saveItems(this.inventory.items);
        });
    }

    get cheatsActivated() {
        return this.dataManager.cheats;
    }

    get isServantActive() {
        return this.dataManager.servantTime > new Date();
    }

    get tier(): string {
        let result = 1;
        rewards.forEach(reward => {
            if (this.rewards[reward.key] === true && reward.tier && result < reward.tier) {
                result = reward.tier;
            }
        });
        return result.toString();
    }

    addCard(card: Card) {
        this.cards.push(card);
        this.dataManager.cards.push({
            name: card.name,
            x: card.x,
            y: card.y,
            plus: card.plus,
            shield: card.shield,
            luck: card.luck,
            strengthIncrease: card.strengthIncrease,
            speedIncrease: card.speedIncrease,
            healthIncrease: card.healthIncrease,
            criticalIncrease: card.criticalIncrease,
            key: card.key,
        });
        this.dataManager.saveCards(this.dataManager.cards);
    }

    updateCard(name, x, y, plus, shield, luck, strengthIncrease, speedIncrease, healthIncrease, criticalIncrease) {
        const card = this.dataManager.cards.find(card => card.name === name);
        if (card) {
            card.x = x;
            card.y = y;
            if (plus) {
                card.plus = plus;
            }
            if (shield) {
                card.shield = shield;
            }
            if (luck) {
                card.luck = luck;
            }
            if (strengthIncrease) {
                card.strengthIncrease = strengthIncrease;
            }
            if (speedIncrease) {
                card.speedIncrease = speedIncrease;
            }
            if (healthIncrease) {
                card.healthIncrease = healthIncrease;
            }
            if (criticalIncrease) {
                card.criticalIncrease = criticalIncrease;
            }
        }
        this.dataManager.saveCards(this.dataManager.cards);
    }

    removeCard(name) {
        this.cards = this.cards.filter(card => card.name !== name);
        this.dataManager.cards = this.dataManager.cards.filter(card => card.name !== name);
        this.dataManager.saveCards(this.dataManager.cards);
    }

    getRewardAttribute(name: string) {
        let result = 0;
        rewards.forEach(reward => {
            if (this.rewards[reward.key] === true) {
                reward.attributes.forEach(attribute => {
                    if (attribute.name === name) {
                        result += attribute.value;
                    }
                });
            }
        });
        return result;
    }
}