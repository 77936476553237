import Button from './Button';
import CardBase from './CardBase';

export default class CardServant extends CardBase {
    constructor(data) {

        const textOptions = {
            font: "30px Arial",
            fill: "#111"
        };
        const textTitleOptions = {
            font: "bold 35px Arial",
            fill: "#111"
        };

        let { scene, x, y } = data;
        const ressource = new Phaser.GameObjects.Image(scene, 0, -200, 'slime');
        const nameText = new Phaser.GameObjects.Text(scene, 0, -50, 'Slime Servant', textTitleOptions);
        const line01 = new Phaser.GameObjects.Text(scene, -230, 50, '- Level increase chance +10%', textOptions);
        const line02 = new Phaser.GameObjects.Text(scene, -230, 85, '- Prepares materials while offline', textOptions);
        const line03 = new Phaser.GameObjects.Text(scene, -230, 120, '- Offline time increases from 2h to 6h', textOptions);
        const line04 = new Phaser.GameObjects.Text(scene, -230, 155, '- Sweeps the ads away', textOptions);
        const footer = new Phaser.GameObjects.Text(scene, -120, 380, 'free in open beta', textOptions);
        const button = new Button({ scene, x: 0, y: 300, width: 300, height: 100, text: 'Hire $1/week' });

        super(scene, x, y, [ressource, nameText, line01, line02, line03, line04, button, footer]);

        this.ressource = ressource;
        this.nameText = nameText;
        this.scene = scene;
        this.button = button;

        this.frameTime = 0;

        this.card.setOrigin(0.5, 0.5);
        this.nameText.setOrigin(0.5, 0.5);

        this.ressource.setVisible(this.isFlipped);
        // this.ressource.setScale(0.5);
        this.setVisible(false);
        this.healthBar.setVisible(false);
        this.setDepth(9);

        this.button.events.on('click', () => {
            this.button.setVisible(false);
            const date = new Date(this.scene.player.dataManager.servantTime);
            date.setDate(date.getDate() + 7);
            this.scene.player.dataManager.saveServant(date)
        });

        this.scene.add.existing(this);
        this.scene.events.on('update', this.update, this);
    }

    toggleFlip() {
        this.setVisible(!this.isFlipped);
        this.scene.tweens.add({
            targets: this.card,
            scaleX: this.cardScale * 0.01,
            ease: 'Linear',
            duration: 150,
            onComplete: () => {
                this.ressource.setVisible(!this.isFlipped);
                this.nameText.setVisible(!this.isFlipped);
                this.background.setVisible(!this.isFlipped);
                this.scene.tweens.add({
                    targets: this.card,
                    scaleX: this.isFlipped ? this.cardScale : this.cardScale * 2.5,
                    scaleY: this.isFlipped ? this.cardScale : this.cardScale * 2.5,
                    ease: 'Linear',
                    duration: 150,
                    onComplete: () => {
                        this.isFlipped = !this.isFlipped;

                        if (this.isFlipped) {
                            
                        }
                    },
                })
            },
        });
    }

    update(time, delta) {
        this.frameTime += delta;
        const every = 1000;
        while (this.frameTime > every) {
            this.frameTime -= every;

            const date = new Date(this.scene.player.dataManager.servantTime);
            if (date > new Date()) {
                this.button.setVisible(false);
            } else {
                this.button.setVisible(true);
            }
        }
    }
}
